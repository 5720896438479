import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {CandidateModel} from '../../../Core/Models/CandidateModelModell.Model';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {CandidateProfileModel} from '../../../Core/Models/CandidateProfile.Model';
import {Guid} from 'guid-typescript';

@Injectable({
  providedIn: 'root'
})
export class CandidatesprofileService {

  baseUrl = environment.baseUrl;
  candidateEndpoints = `${this.baseUrl}/Profiles/CandidatesProfile/`;
  constructor(private https: HttpClient) { }

  GetById(id: Guid): Observable<CandidateProfileModel> {
    return this.https.get<CandidateProfileModel>(`${this.candidateEndpoints}GetById?id=${id}`);
  }

  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<CandidateProfileModel[]> {
    return this.https.post<CandidateProfileModel[]>(`${this.candidateEndpoints}all`, filterCriteria);
  }

  Upsert(model: CandidateProfileModel): Observable<string> {
    return this.https.post<string>(`${this.candidateEndpoints}Upsert`, model);
  }
  isActive(id: Guid): Observable<string> {
    return this.https.get<string>(`${this.candidateEndpoints}isActive?id=${id}`);
  }
}
