import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ModalService} from '../../_modal';
import {ApplicationModel} from '../../../Core/Models/Application.Model';
import {ApplicationsService} from '../../../Services/ApplicationsService/Applications.Service';
import {DocumentsService} from '../../../Services/DocumentsService/documents.service';
import {Guid} from 'guid-typescript';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import * as moment from 'moment';
import {DomSanitizer, Title} from '@angular/platform-browser';


@Component({
  selector: 'app-application-details',
  templateUrl: './application-details.component.html',
  styleUrls: ['./application-details.component.scss']
})
export class ApplicationDetailsComponent implements OnInit {

  title = 'Application-Details';
  public applicationName: string;
  public applicationDate: Date;
  public linkForm: FormGroup;
  public currentURL: string;
  public socialLink: string;
  application: ApplicationModel;
  apps : ApplicationModel[];
  currentDate: Date;

  isLoaded = false;
  public filterCriteria: FilterCriteria;

  constructor(private applicationsService: ApplicationsService,
              private documentsService: DocumentsService,
              private router: Router,
              private formBuilder: FormBuilder,
              public modalService: ModalService,
              public dom: DomSanitizer,
              private route: ActivatedRoute,
              private titleService: Title) {}

   ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.currentDate = new Date();
    this.applicationDate = new Date();
    this.applicationName = 'Graduate Application';
    this.currentURL = window.location.href;
    this.socialLink = '';
    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.ApplicationsKeys = Array<string>();
    this.filterCriteria.ApplicationsKeys = [];
    this.linkForm = this.formBuilder.group({
      sharableLink: this.currentURL
    });
    this.apps = new Array<ApplicationModel>();
    this.application = new ApplicationModel();
    this.getApplication();



  }

  getApplication(): void {
    const applicationId = this.route.snapshot.paramMap.get('applicationId');

    this.applicationsService.GetById(Guid.parse(applicationId)).subscribe(application => {
      this.application = application;

      if (!this.application.bannerImage) {
        this.application.bannerImage = 'assets/images/hazie-banner.jpg';
      }

      this.isLoaded = true;
    });
  }

//   async getApplication() {
//
//     const applicationId = this.route.snapshot.paramMap.get('applicationId');
//     console.log(applicationId);
//     const observableApplication = this.applicationsService.GetById(Guid.parse(applicationId));
//     const resultApplication = await observableApplication.toPromise();
//     this.application = resultApplication;
//
// console.log(this.application);
//
//  /*   const observableDoc = this.documentsService.GetById(Guid.parse(this.application.bannerImage));
//     const resultDoc = await observableDoc.toPromise();
//
//
//     const observableUrl = this.documentsService.GetFileURL(resultDoc.fileName);
//     const resultUrl = await observableUrl.toPromise();
//
//     this.application.bannerImage = resultUrl;*/
//
//
//     const observableDoc = this.documentsService.GetById(Guid.parse(this.application.bannerImage));
//     const resultDoc = await observableDoc.
//       toPromise().then( res => {
//
//         if (res){
//           this.documentsService.GetFileURL(res.fileName).subscribe(finalResult =>{
//             this.application.bannerImage = finalResult;
//
//
//           });
//         }
//       },
//       msg => {
//         console.log(msg);
//         this.application.bannerImage = '/assets/images/hazie-banner.jpg';
//
//       })
//     ;
//     this.isLoaded = true;
//
//   }

  shareToSocials(socialNetwork: string): void {
    if (socialNetwork === 'facebook') {
      this.socialLink = 'https://www.facebook.com/sharer/sharer.php?u=' + this.currentURL;
    } else if (socialNetwork === 'twitter') {
      this.socialLink = 'https://twitter.com/intent/tweet?url=' + this.currentURL;
    } else if (socialNetwork === 'linkedin') {
      this.socialLink = 'https://www.linkedin.com/shareArticle?url=' + this.currentURL;
    } else if ('link') {
      const textArea: HTMLTextAreaElement = document.createElement('textarea') as HTMLTextAreaElement;

      textArea.value = decodeURIComponent(this.currentURL);
      document.body.appendChild(textArea);

      // highlight TextArea to copy the sharing link
      if (true) {
        const range = document.createRange();
        range.selectNodeContents(textArea);
        const selection = document.defaultView.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
        textArea.readOnly = true;
        textArea.setSelectionRange(0, 999999);
      } else {
        textArea.select();
      }
      document.execCommand('copy');
      document.body.removeChild(textArea);

    }
  }



  goToApplicationQuestions(id: string): void {
    const authentication = window.localStorage.getItem('auth');
    if (authentication === 'true'){
      this.router.navigate(['candidate-questions', id]);

    }else{
      this.router.navigate(['login']);
    }
  }


  applicationClosed(x: ApplicationModel): boolean  {
    const date = new Date(x.expiryDate);
    if (moment(date).isBefore(this.currentDate)) {
      return false;
    } else if (moment(date).isSame(this.currentDate)) {
      return false;
    } else if (moment(new Date(date)).isAfter(this.currentDate)) {
      return  true;
    }
  }

}

