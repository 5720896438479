import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {UserCredentialModel} from '../../Core/Models/UserCredentialModel';
import * as moment from 'moment';
import {AuthenticationResponseModel} from '../../Core/Models/AuthenticationResponseModel';
import {environment} from '../../../environments/environment';
import {UserRegistrationModel} from '../../Core/Models/UserRegistration.Model';
import {ConfirmModel} from '../../Core/Models/Confirm.Model';
import {ResetPasswordModel} from '../../Core/Models/ResetPassword.Model';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  private cookieKey = 'userCredential';
  private isAuthenticatedSource = new BehaviorSubject<boolean>(this.checkIfAuthenticated());
  isAuthenticated = this.isAuthenticatedSource.asObservable();
  baseUrl = environment.identityUrl;
  localUrl = 'http://localhost:5006/';
  IdentityEndpoints = `${this.baseUrl}/`;
  IdentintyAPIEnpoint = `${environment.identityApiUrl}/`;
  ReturnUrl = environment.webUrl;
  allowADUserRegistration = environment.allowADUserRegistration;
  forceADUserRegistration = environment.forceADUserRegistration;

  ClientId = environment.clientId;


  constructor(private http: HttpClient, private cookieService: CookieService) { }

  getUserCredential(): UserCredentialModel {
    const userCredential = new UserCredentialModel();

    userCredential.fromJson(this.cookieService.get(this.cookieKey));
    return userCredential;
  }

  checkIfAuthenticated(): boolean {
    if (!this.cookieService.check(this.cookieKey)) {
      return false;
    }

    const userCredential = this.getUserCredential();
    const tokenExists = userCredential.accessToken != null && userCredential.accessToken.length > 0;
    const isExpired = moment(userCredential.tokenExpiryDate).isBefore(moment());

    return tokenExists && !isExpired;
  }

  hasRefreshToken(): boolean {
    if (!this.cookieService.check(this.cookieKey)) {
      return false;
    }

    const userCredential = this.getUserCredential();

    return userCredential.refreshToken != null && userCredential.refreshToken.length > 0;
  }

  refreshToken(): Promise<AuthenticationResponseModel> {
    const userCredential = this.getUserCredential();
    const body = `grant_type=refresh_token&refresh_token=${userCredential.refreshToken}&client_id=
    ${environment.clientId}&client_secret=${environment.clientSecret}`;

    return this.http.post(`${environment.identityUrl}/connect/token`, body,
      {headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')})
      .toPromise()
      .then(result => {
        const json = JSON.parse(JSON.stringify(result));

        userCredential.accessToken = json.access_token;
        userCredential.refreshToken = json.refresh_token;
        userCredential.tokenExpiryDate = moment().add(json.expires_in, 'seconds').toDate().toDateString();

        this.cookieService.set(this.cookieKey, JSON.stringify(userCredential));
        this.isAuthenticatedSource.next(this.checkIfAuthenticated());
        return new AuthenticationResponseModel(true, []);
      })
      .catch(response => {
        this.cookieService.delete(this.cookieKey);
        this.isAuthenticatedSource.next(this.checkIfAuthenticated());
        return Promise.reject<AuthenticationResponseModel>(new AuthenticationResponseModel(false, [response.error.error_description]));
      });
  }

  login(username: string, password: string): Promise<AuthenticationResponseModel> {
    const body = `grant_type=password&client_id=${environment.clientId}&client_secret=${environment.clientSecret}&username=${username}&password=${password}`;
    /*const body = 'grant_type=password&username=' + username + '&password=' + password +
      '&scope=EdconApi nxgnIdentityServer offline_access';*/

    return this.http.post(`${environment.identityUrl}/connect/token`, body,
      {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/x-www-form-urlencoded')
          .set('Authorization', `Basic ${environment.clientSecret}`)
      })
      .toPromise()
      .then(result => {
        const json = JSON.parse(JSON.stringify(result));

        const userCredential = new UserCredentialModel();
        userCredential.username = username;
        userCredential.accessToken = json.access_token;
        userCredential.refreshToken = json.refresh_token;
        userCredential.tokenExpiryDate = moment().add(json.expires_in, 'seconds').toDate().toDateString();


        this.cookieService.set(this.cookieKey, JSON.stringify(userCredential));
        this.isAuthenticatedSource.next(this.checkIfAuthenticated());
        return new AuthenticationResponseModel(true, []);
      })
      .catch(response => {
        this.cookieService.delete(this.cookieKey);
        this.isAuthenticatedSource.next(this.checkIfAuthenticated());
        return Promise.reject<AuthenticationResponseModel>(new AuthenticationResponseModel(false, [response.error.error_description]));
      });
  }

  logout() {
    this.cookieService.delete(this.cookieKey);
    this.isAuthenticatedSource.next(this.checkIfAuthenticated());
  }


  registration(registrationModel: UserRegistrationModel){

    return this.http.post<UserRegistrationModel>(`${this.IdentintyAPIEnpoint}Registration`, registrationModel);
  }

  confirmation(confirm: ConfirmModel){
    return this.http.post<ConfirmModel>(`${this.IdentintyAPIEnpoint}account/ConfirmEmail`, confirm);
  }
  getRegistrationToken(id){
    return this.http.get(`${this.IdentintyAPIEnpoint}RegistrationToken/TokenIsValid/?id=${id}`);
  }

  verifyEmail(id, password){
    return this.http.get(`${this.IdentintyAPIEnpoint}/VerifyEmail/?id=${id}&password=${password}&baseUrl=${environment.webUrl}`, null);

  }
  sendRecoveryEmail(email: string){
    return this.http.get(`${this.IdentintyAPIEnpoint}Registration/ForgetPassword/?email=${email}`);
  }
  changePassword(resetPasswordModel: ResetPasswordModel){
    return this.http.post<ConfirmModel>(`${this.IdentintyAPIEnpoint}registration/ResetPassword`, resetPasswordModel);
  }
}
