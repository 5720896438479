<div class="card">
  <img [src]=application.bannerImage alt="" class="banner">
  <div class="info">
    <h5 class="card-header">
      {{application.title}}
    </h5>
    <div class="location-and-date">
      <div class="card-location"><i class="fa fa-map-marker" aria-hidden="true">&nbsp;</i>{{application.location.lineone}}</div>
      <div class="card-date">
        Available: <strong>{{application.availableDate | date}}</strong><br>
        Expires: <strong>{{application.expiryDate | date}}</strong>
      </div>
    </div>
    <hr>
    <div class="card-content">
      {{application.shortDescription}}
    </div>
    <div class="card-buttons-group">
      <button type="button" (click)="goToApplicationDetails(this.application?.id)" class="button details-button">Details</button>
      <button *ngIf="applicationClosed(application) === true" class="button apply" (click)="goToApplicationQuestions(application?.id.toString())">Apply</button>
      <button *ngIf="applicationClosed(application) === false" class="button application-closed" >Application Closed</button>
    </div>
  </div>
</div>
