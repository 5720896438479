import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserRegistrationModel} from '../../../Core/Models/UserRegistration.Model';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {CandidateModel, LocationModel} from '../../../Core/Models/CandidateModelModell.Model';
import {CandidatesService} from '../../../Services/CandidatesService/candidates.service';
import {CandidatesprofileService} from '../../../Services/ProfilesService/CandidateService/candidatesprofile.service';
import {CandidateProfileModel} from '../../../Core/Models/CandidateProfile.Model';
import {LanguageEnum} from '../../../Core/Enums/Language.Enum';
import {DisabilityEnum} from '../../../Core/Enums/Disability.Enum';
import {PopulationGroupEnum} from '../../../Core/Enums/PopulationGroup.Enum';
import {Gender} from '../../../Core/Models/Gender.Model';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
    disablePassword: boolean;
    enableAD: boolean;
    registerForm: FormGroup;
    confirmPassword: FormControl;
    useAD: boolean;
    errorMessages: string[];
    submitted = false;
    public applicationId;
    user: UserRegistrationModel;

    constructor(private authenticationService: AuthenticationService,
                private router: Router, private candidatesService: CandidatesService,
                private candidatesprofileService: CandidatesprofileService,
                private route: ActivatedRoute,
                private formBuilder: FormBuilder) {

        this.user = new UserRegistrationModel();

        this.applicationId = this.route.snapshot.queryParams.JobId;

        this.disablePassword = this.authenticationService.forceADUserRegistration;

        this.useAD = this.authenticationService.allowADUserRegistration;


    }

    ngOnInit(): void {
        this.initiateForm();
    }

    initiateForm(): void {
        this.registerForm = this.formBuilder.group({
            firstName: ['', [Validators.required, Validators.minLength(3)]],
            lastName: ['', [Validators.required, Validators.minLength(3)]],
            email: ['',
                [Validators.required,
                    Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')]],
            idNumber: ['',
                    [Validators.required,
                    Validators.pattern(/^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/)]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            // username: this.username,
            // confirmPassword: this.confirmPassword,
        });
    }

    markFormFieldsAsTouched(): void {
        this.firstName.markAsTouched();
        this.lastName.markAsTouched();
        this.email.markAsTouched();
        this.idNumber.markAsTouched();
        this.password.markAsTouched();
    }

    /* -RegisterForm Getters- */
    get firstName(): AbstractControl {
        return this.registerForm.get('firstName');
    }

    get lastName(): AbstractControl {
        return this.registerForm.get('lastName');
    }

    get email(): AbstractControl {
        return this.registerForm.get('email');
    }

    get idNumber(): AbstractControl {
        return this.registerForm.get('idNumber');
    }

    get password(): AbstractControl {
        return this.registerForm.get('password');
    }


    async register() {

        this.markFormFieldsAsTouched();

        if (this.registerForm.valid) {
            this.errorMessages = [];
            this.user.ClientId = this.authenticationService.ClientId;
            this.user.Email = this.email.value;
            this.user.LastName = this.lastName.value;
            this.user.FirstName = this.firstName.value;
            this.user.UseAD = this.useAD;
            this.user.Password = this.password.value;
            /*this.user.ConfirmPassword = this.confirmPassword.value;
            this.user.Username = this.username.value;*/
            this.user.IsDashboard = false;
            this.user.ReturnUrl = this.authenticationService.ReturnUrl;
            const user = this.authenticationService.getUserCredential();
            const candidateUser = new CandidateModel();
            candidateUser.email = user.username;
            candidateUser.identityNumber = this.idNumber.value;
            const filterCriteria = new FilterCriteria();
            filterCriteria.IdNumberKeys = new Array<string>();
            filterCriteria.IdNumberKeys.push(candidateUser.identityNumber);
            filterCriteria.EmailKey = user.username;
            const observableCandites = this.candidatesService.GetByFilterCriteria(filterCriteria);
            const x = await observableCandites.toPromise();
            console.log(x);

            if (x.length === 0) {

                candidateUser.email = this.email.value;
                candidateUser.name = this.user.FirstName;
                candidateUser.surname = this.user.LastName;
                candidateUser.gender = 0;
                candidateUser.countryOfBirth = 'South Africa';
                candidateUser.passportNumber = 0;

                candidateUser.isActive = false;
                candidateUser.location = new LocationModel();
                candidateUser.location.candidateId = candidateUser.id;
                candidateUser.location.code = 20009;
                candidateUser.location.lineone = '';
                candidateUser.location.linetwo = '';
                candidateUser.location.linethree = '';
                candidateUser.location.linefour = '';
                candidateUser.currentStage = '';
                this.candidatesService.Upsert(candidateUser)
                    .subscribe(o => {
                            console.log('Successfull');
                            const candidateProfileModel = new CandidateProfileModel();
                            candidateProfileModel.candidateId = candidateUser.id;
                            candidateProfileModel.email = candidateUser.email;
                            candidateProfileModel.cellphoneNumber = 0;
                            candidateProfileModel.userName = candidateUser.email;
                            candidateProfileModel.currentCity = 'Not Specified';
                            candidateProfileModel.contactDetails = 0;
                            candidateProfileModel.idNumber = +candidateUser.identityNumber;
                            candidateProfileModel.populationGroup = PopulationGroupEnum.Undefined;
                            candidateProfileModel.firstName = candidateUser.name;
                            candidateProfileModel.lastName = candidateUser.surname;
                            candidateProfileModel.gender = Gender.Undisclosed;
                            candidateProfileModel.isActive = true;
                            candidateProfileModel.homeLanguage = LanguageEnum.Undefined;
                            candidateProfileModel.dateOfBirth = new Date();
                            candidateProfileModel.disability = DisabilityEnum.Undefined;
                            candidateProfileModel.isNewAccount = true;

                            this.candidatesprofileService.Upsert(candidateProfileModel)
                                .subscribe(profile => {
                                    console.log('success profile');
                                    this.authenticationService.registration(this.user).subscribe(data => {

                                        // setup for an email notification here
                                        localStorage.setItem('verify', 'register');
                                        this.router.navigate(['./verifyUser']);

                                    }, response => {
                                        this.submitted = true;
                                        // console.log(response, this.submitted);
                                        // console.log('Failed to complete registration');
                                        this.errorMessages.push('Failed to complete registration.');
                                        // this.formFields.password.setErrors({incorrect: true});
                                    });
                                });
                        },
                        response => {
                            console.log('failed');
                        }
                    );

            } else {
                this.submitted = true;
                // console.log(response, this.submitted);
                // console.log('Failed to complete registration');
                this.errorMessages.push('Failed to complete registration. Account exist\'s!!');
                // this.formFields.password.setErrors({incorrect: true});
            }
        }

    }
}



