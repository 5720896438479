export class CreateAssignmentModel {
  stageId: string;
  candidateId: string;
  firstName: string;
  lastName: string;
  identityNumber: string;
  email: string;
  applicationId: string;
  projectId: number;
  nationalityCode: string;
}
