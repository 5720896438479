<div class="container background">

  <div class="container--left-view">
    <div class="container--left-view-company-logo">
      <a routerLink="/applications">
        <img  class="hazie-logo-left" src="../../../../assets/logos/Hazie-logo-white.png" alt="Card image cap">
      </a>
    </div>
  </div>

  <div class="container--right-view">
    <div *ngIf="renderedQuestion" class="container--right-view-card">
      <div class="container--right-view-card-progress">
        <div class="container--right-view-card-progress-back" *ngIf="this.showBackButton"  (click)="onBackButton()">
          <img class="onArrowButton" src="../../../../assets/images/arrow.svg" >
          <div class="onBackButton"> BACK</div>
        </div>

        <div class="container--right-view-card-progress-back" *ngIf="!this.showBackButton"  >
          <div class="onBackButton" > </div>
        </div>

        <div class="container--right-view-card-progress-bar">
          <span class="container--right-view-card-progress-bar-fill" style="{{progressStyling}}"></span>
        </div>
      </div>

      <div class="container--right-view-card-speech" *ngIf="!isComms">
        <div class="container--right-view-card-speech-bubble">
          <h5>{{renderedQuestion.question}}</h5>
        </div>
      </div>

      <div class="container--right-view-card-speech" *ngIf="isComms==true">
        <div class="container--right-view-card-speech-bubble">
          <h5>{{this.comsMessage}}</h5>
        </div>
      </div>


      <div class="container--right-view-card-logo">
        <img  class="hazie-logo-middle" src="../../../../assets/logo/icon.png" alt="Card image cap">
      </div>

      <div class="container--right-view-card-questions" *ngIf="!isComms">
        <div class="container--right-view-card-questions-question">
          <app-input-text class="step-inputs"
                          *ngIf="renderedQuestion.questionType === 0"
                          [onNextButtonClicked]="onNextButtonClicked"
                          [renderedQuestion]="renderedQuestion"
                          [isProfile]="false"
                          [candidateId]="Candidate.id"
                          (validate)="validateOnNextButton($event)"
                          [currentStage]="currentStage"
                          (isPromoted)="isPromotedToTheNextStage($event)"  >
          </app-input-text>

<!--          <app-input-upload class="step-inputs"-->
<!--                            *ngIf="renderedQuestion.questionType === 1"-->
<!--                            [onNextButtonClicked]="onNextButtonClicked"-->
<!--                            [renderedQuestion]="renderedQuestion"-->
<!--                            [applicationId]="applicationModel.id"-->
<!--                            [companyId]="applicationModel.companyId"-->
<!--                            [candidateId]="Candidate.id"-->
<!--                            (validate)="validateOnNextButton($event)" >-->
<!--          </app-input-upload>-->

<!--          <app-input-select class="step-inputs"-->
<!--                            *ngIf="renderedQuestion.questionType === 2"-->
<!--                            [onNextButtonClicked]="onNextButtonClicked"-->
<!--                          [renderedQuestion]="renderedQuestion"-->
<!--                            [candidateId]="Candidate.id"-->
<!--                            (validate)="validateOnNextButton($event)"-->
<!--                            [currentStage]="currentStage"-->
<!--                            (isPromoted)="isPromotedToTheNextStage($event)" >-->
<!--          </app-input-select>-->

<!--          <app-input-multiselect class="step-inputs"-->
<!--                                 *ngIf="renderedQuestion.questionType === 3"-->
<!--                                 [onNextButtonClicked]="onNextButtonClicked"-->
<!--                                 [renderedQuestion]="renderedQuestion"-->
<!--                                 [candidateId]="Candidate.id"-->
<!--                                 (validate)="validateOnNextButton($event)"-->
<!--                                 [currentStage]="currentStage"-->
<!--                                 (isPromoted)="isPromotedToTheNextStage($event)" >-->
<!--          </app-input-multiselect>-->

          <app-input-statement class="step-inputs"
                               *ngIf="renderedQuestion.questionType === 4"
                               [onNextButtonClicked]="onNextButtonClicked"
                               [renderedQuestion]="renderedQuestion"
                               (validate)="validateOnNextButton($event)" >
          </app-input-statement>

        </div>
      </div>

      <div class="container--right-view-card-next">
        <button type="submit" class="button" *ngIf="!isComms" (click)="ChildComponentOnNextClicked()">Next</button>
        <button type="submit" class="button" *ngIf="isComms" (click)=" nextStageComms(true)">NextStage</button>
      </div>
    </div>
  </div>

</div>
