import { Component, OnInit } from '@angular/core';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {LoginModel} from '../../../Core/Models/Login.Model';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  model: LoginModel;
  returnUrl: string;
  isBusy: boolean;
  errorMessages: string[];
  message: string;
  title = 'Sign in';
  submitted = false;
  loginForm: FormGroup;

  constructor(private authenticationService: AuthenticationService,
              private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.initiateForm();

    this.route.params.subscribe(params => {
      this.message = params.message;
    });

    this.model = new LoginModel();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';

  }

  initiateForm(): void {
    this.loginForm = this.formBuilder.group({
      email: ['',
        [Validators.required,
          Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  login(): void {
    /* -Mark form as touched- */
    this.email.markAsTouched();
    this.password.markAsTouched();

    if (this.loginForm.valid) {
      this.isBusy = true;
      this.errorMessages = [];
      if (this.model.email === undefined || this.model.password === undefined){
        this.submitted = true;
        this.errorMessages.push('Login Failed. Please double check your credentials');
        return;
      }


      this.authenticationService.login(this.model.email.toLowerCase(), this.model.password).then((response) => {
        if (response.isValid) {
          const user = this.authenticationService.getUserCredential();
          this.route.queryParamMap.subscribe(o => {
            if (o[`params`][`returnUrl`]) {
              this.router.navigate([o[`params`][`returnUrl`]]);
            }
            else {
              //this.router.navigate(['./onBoarding']);
              this.router.navigate(['./applications']);
            }
          });
        } else {
          this.submitted = true;
          this.errorMessages = response.errorMessages;
        }
      }).catch((response) => {
        this.submitted = true;
        this.errorMessages.push('Login Failed. Please double check your credentials');
      });
    }
  }

  /* -LoginForm Getters- */
  get email(): AbstractControl {
    return this.loginForm.get('email');
  }

  get password(): AbstractControl {
    return this.loginForm.get('password');
  }
}
