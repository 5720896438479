export class UserCredentialModel {
  username: string;
  accessToken: string;
  tokenExpiryDate: string;
  refreshToken: string;

  constructor() {
  }

  fromJson(jsonString: string) {

    if(jsonString === ''){

    }else{
      const json = JSON.parse(jsonString);

      this.username = json.username;
      this.accessToken = json.accessToken;
      this.tokenExpiryDate = json.tokenExpiryDate;
      this.refreshToken = json.refreshToken;

    }

  }
}
