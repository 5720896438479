import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import {CandidateProfileModel} from '../../../../../Core/Models/CandidateProfile.Model';
import {CandidatesprofileService} from '../../../../../Services/ProfilesService/CandidateService/candidatesprofile.service';
import {FilterCriteria} from '../../../../../Core/Models/FilterCriteria';
import {CandidatesService} from '../../../../../Services/CandidatesService/candidates.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ToasterNotificationService} from '../../../../../Services/ToasterNotificationService/ToasterNotification.Service';
import {Gender} from '../../../../../Core/Models/Gender.Model';
import {Title} from '@angular/platform-browser';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.scss']
})
export class ProfileEditComponent implements OnInit {

  title = 'Profile-Edit';
  candidate = new  CandidateProfileModel();
  profileForm: FormGroup;

  constructor(public location: Location, private profilesService: CandidatesprofileService,
              private candidatesService: CandidatesService,  private router: Router,
              private route: ActivatedRoute,
              private notification: ToasterNotificationService,
              private titleService: Title,
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.getProfile(this.route.snapshot.params.id);
  }

  getProfile(id): void {
    const filter = new FilterCriteria();
    filter.candidateIdKeys = [id];
    this.profilesService.GetByFilterCriteria(filter)
      .subscribe(x => {
        if (x.length > 0) {
          this.candidate = x.pop();

          /*- Cellphone number issue work around -*/
          if (this.candidate.cellphoneNumber.toString().length === 1) {
            this.candidate.cellphoneNumber = '0';
          } else {
            this.candidate.cellphoneNumber = '0' + this.candidate.cellphoneNumber;
          }

          this.initiateForm(this.candidate);
        }else {
          this.candidate = new CandidateProfileModel();
          this.candidate.candidateId = id;
        }
      });
  }

  initiateForm(candidate: CandidateProfileModel): void {
    this.profileForm = this.formBuilder.group({
      firstName: [candidate.firstName, [Validators.required, Validators.minLength(2)]],
      lastName: [candidate.lastName, [Validators.required, Validators.minLength(2)]],
      idNumber: [candidate.idNumber, [Validators.required, Validators.minLength(13), Validators.maxLength(13)]],
      homeLanguage: [candidate.homeLanguage, [Validators.required]],
      gender: [candidate.gender, [Validators.required]],
      currentCity: [candidate.currentCity, [Validators.required, Validators.minLength(3)]],
      populationGroup: [candidate.populationGroup, [Validators.required]],
      disability: [candidate.disability, [Validators.required]],
      contactNumber: [candidate.cellphoneNumber, [Validators.required, Validators.minLength(3)]],
      email: [candidate.email, [Validators.required, Validators.pattern('^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$')]]
    });
  }

  back(): void {
    this.location.back();
  }

  submit(): void {

    this.markFormFieldsAsTouched();

    if (this.profileForm.valid) {
      if (typeof this.candidate.idNumber === 'string' ||
        typeof this.candidate.cellphoneNumber === 'string' ) {
        this.candidate.idNumber = +this.candidate.idNumber;
        this.candidate.cellphoneNumber = +this.candidate.cellphoneNumber;
      }

      this.profilesService.Upsert(this.candidate)
        .subscribe(x => {
          this.notification.Success('Profile Updated Successfully');
          this.router.navigate(['profile']);
          console.log('success');
        });
    } else {
      this.notification.Alert('Profile is incomplete');
    }
  }

  getCorrectProfileIcon(candidate: CandidateProfileModel): string {
    if (candidate.gender === Gender.Male) {
      return 'assets/icons/purple/man.svg';
    } else if (candidate.gender === Gender.Female) {
      return 'assets/icons/purple/woman.svg';
    } else {
      return 'assets/icons/purple/user-2.svg';
    }
  }

  /* -Mark form as touched- */
  markFormFieldsAsTouched(): void {
    this.firstName.markAsTouched();
    this.lastName.markAsTouched();
    this.idNumber.markAsTouched();
    this.homeLanguage.markAsTouched();
    this.gender.markAsTouched();
    this.currentCity.markAsTouched();
    this.populationGroup.markAsTouched();
    this.disability.markAsTouched();
    this.contactNumber.markAsTouched();
    this.email.markAsTouched();
  }

  /* --EditApplicationForm Getters-- */
  get firstName(): AbstractControl {
    return this.profileForm.get('firstName');
  }

  get lastName(): AbstractControl {
    return this.profileForm.get('lastName');
  }

  get idNumber(): AbstractControl {
    return this.profileForm.get('idNumber');
  }

  get homeLanguage(): AbstractControl {
    return this.profileForm.get('homeLanguage');
  }

  get gender(): AbstractControl {
    return this.profileForm.get('gender');
  }

  get currentCity(): AbstractControl {
    return this.profileForm.get('currentCity');
  }

  get populationGroup(): AbstractControl {
    return this.profileForm.get('populationGroup');
  }

  get disability(): AbstractControl {
    return this.profileForm.get('disability');
  }

  get contactNumber(): AbstractControl {
    return this.profileForm.get('contactNumber');
  }

  get email(): AbstractControl {
    return this.profileForm.get('email');
  }

}
