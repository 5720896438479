<app-navbar></app-navbar>
<div class="main-container">
  <div class="background-canvas">
    <div class="card">
      <div class="header">
        <img src="{{getCorrectProfileIcon(candidate)}}" alt="profile-icon">
        <div class="name">{{candidate.firstName}} {{candidate.lastName}}</div>
      </div>
      <div class="main-section">
        <form [formGroup]="profileForm">
          <div class="row">
            <div class="col-25">
              <label for="firstName">First Name:</label>
            </div>
            <div class="col-75">
              <input formControlName="firstName" type="text" id="firstName" [(ngModel)]="candidate.firstName" name="firstName" >
              <div class="error-messages" *ngIf="firstName.touched">
                <div *ngIf="firstName.invalid && firstName.dirty">* First Name needs to be at least 3 characters </div>
                <div *ngIf="!firstName.value">* First Name can't be blank</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="lastName">Last Name:</label>
            </div>
            <div class="col-75">
              <input formControlName="lastName" type="text" id="lastName" [(ngModel)]="candidate.lastName" name="lastName" >
              <div class="error-messages" *ngIf="lastName.touched">
                <div *ngIf="lastName.invalid && lastName.dirty">* Last Name needs to be at least 3 characters </div>
                <div *ngIf="!lastName.value">* Last Name can't be blank</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="id">ID Number:</label>
            </div>
            <div class="col-75">
              <input formControlName="idNumber" type="text" id="id" [(ngModel)]="candidate.idNumber" name="id" >
              <div class="error-messages" *ngIf="idNumber.touched">
                <div *ngIf="idNumber.invalid && idNumber.dirty">* ID Number should be 13 digits long </div>
                <div *ngIf="!idNumber.value">* ID Number can't be blank</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="language">Home Language:</label>
            </div>
            <div class="col-78">
              <select formControlName="homeLanguage" id="language" class="select"  [(ngModel)]="this.candidate.homeLanguage" >
                <option>-Select-Option-</option>
                <option [ngValue]="1">English</option>
                <option [ngValue]="2">Afrikaans</option>
                <option [ngValue]="3">isiNdebele</option>
                <option [ngValue]="4">isiXhosa</option>
                <option [ngValue]="5">isiZulu</option>
                <option [ngValue]="6">Sesotho</option>
                <option [ngValue]="7">Sepedi</option>
                <option [ngValue]="8">Setswana</option>
                <option [ngValue]="9">siSwati</option>
                <option [ngValue]="10">Tshivenda</option>
                <option [ngValue]="11">Xitsonga</option>
              </select>
<!--              <input formControlName="homeLanguage" type="text" id="language" required [(ngModel)]="candidate.homeLanguage" name="language" >-->
              <div class="error-messages" *ngIf="homeLanguage.touched">
                <div *ngIf="homeLanguage.value === '-Select-Option-' && homeLanguage.dirty">* Please select correct Home Language </div>
                <div *ngIf="!homeLanguage.value && homeLanguage.invalid">* Home Language can't be blank</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="gender">Gender:</label>
            </div>
            <div class="col-78">
              <select formControlName="gender" id="gender" class="select"  [(ngModel)]="this.candidate.gender" >
                <option>-Select-Option-</option>
                <option [ngValue]="0">Undisclose</option>
                <option [ngValue]="1">Male</option>
                <option [ngValue]="2">Female</option>
              </select>
              <div class="error-messages" *ngIf="gender.touched">
                <div *ngIf="gender.value === '-Select-Option-' && gender.dirty">* Please select correct Gender </div>
                <div *ngIf="!gender.value && gender.invalid">* Gender can't be blank</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="city">Current City:</label>
            </div>
            <div class="col-75">
              <input formControlName="currentCity" type="text" id="city" [(ngModel)]="candidate.currentCity" name="city">
              <div class="error-messages" *ngIf="currentCity.touched">
                <div *ngIf="currentCity.invalid && currentCity.dirty">* Current City needs to be at least 3 characters </div>
                <div *ngIf="!currentCity.value">* Current City can't be blank</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="race">Population Group:</label>
            </div>
            <div class="col-78">
              <select formControlName="populationGroup" id="race" class="select" name="race"  [(ngModel)]="this.candidate.populationGroup" >
                <option>-Select-Option-</option>
                <option [ngValue]="1">African</option>
                <option [ngValue]="2">Colored</option>
                <option [ngValue]="3">White</option>
                <option [ngValue]="4">Indian</option>
                <option [ngValue]="5">Asian</option>
              </select>
              <div class="error-messages" *ngIf="populationGroup.touched">
                <div *ngIf="populationGroup.value === '-Select-Option-' && populationGroup.dirty">* Please select correct Population Group </div>
                <div *ngIf="!populationGroup.value && populationGroup.invalid">* Population Group can't be blank</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="disability">Any Disability:</label>
            </div>
            <div class="col-78">
              <select formControlName="disability" id="disability" class="select" name="disability"  [(ngModel)]="this.candidate.disability" >
                <option>-Select-Option-</option>
                <option [ngValue]="1">No</option>
                <option [ngValue]="2">Yes</option>
              </select>
              <div class="error-messages" *ngIf="disability.touched">
                <div *ngIf="disability.value === '-Select-Option-' && disability.dirty">* Please select correct option </div>
                <div *ngIf="!disability.value && disability.invalid">* Disability can't be blank</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="cellphone">Contact Number:</label>
            </div>
            <div class="col-75">
              <input formControlName="contactNumber" type="text" id="cellphone" [(ngModel)]="candidate.cellphoneNumber" name="cellphone" >
              <div class="error-messages" *ngIf="contactNumber.touched">
                <div *ngIf="contactNumber.invalid && contactNumber.dirty">* Contact Number needs to be at least 3 characters </div>
                <div *ngIf="!contactNumber.value">* Contact Number can't be blank</div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-25">
              <label for="email">Email Address:</label>
            </div>
            <div class="col-75">
              <input formControlName="email" type="text" id="email" [(ngModel)]="candidate.email" name="email" >
              <div class="error-messages" *ngIf="email.touched">
                <div *ngIf="email.invalid && email.dirty">* Email needs to be something like me@example.com </div>
                <div *ngIf="!email.value">* Email can't be blank</div>
              </div>
            </div>
          </div>
        </form>

      </div>
      <div class="footer-section">
        <button type="button" class="button cancel" (click)="back()">CANCEL</button>
        <button type="submit" class="button submit" (click)="submit()">SAVE</button>
      </div>
    </div>
  </div>
</div>


