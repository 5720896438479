import {Component, OnInit} from '@angular/core';
import {ApplicationModel} from '../../../Core/Models/Application.Model';
import {QuestionsAndAnswersService} from '../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';
import {StagesService} from '../../../Services/StagesService/Stages/stages.service';
import {StagescandidatejobService} from '../../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {DocumentsService} from '../../../Services/DocumentsService/documents.service';
import {ActivatedRoute, Router} from '@angular/router';
import {ApplicationsService} from '../../../Services/ApplicationsService/Applications.Service';
import {CandidatesService} from '../../../Services/CandidatesService/candidates.service';
import {Guid} from 'guid-typescript';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {StageModel} from '../../../Core/Models/Stage.Model';
import {QuestionModel} from '../../../Core/Models/Question.Model';
import {StageTypeEnum} from '../../../Core/Enums/StageType.Enum';
import {QuestionTypes} from '../../../Core/Enums/QuestionTypes.Enum';
import {CandidateAnswerModel} from '../../../Core/Models/CandidateAnswer.Model';
import {CandidateStageProgressModel} from '../../../Core/Models/CandidateStageProgress.Model';
import {StageCandidateApplicationProgress} from '../../../Core/Enums/Progress.Enums';
import {DocumentUploadModel} from '../../../Core/Models/DocumentUpload.Model';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {CreateAssignmentModel} from '../../../Core/Models/CreateAssignment.Model';
import {CandidateModel} from '../../../Core/Models/CandidateModelModell.Model';
import {OdysseyService} from '../../../Services/OdysseyService/Odyssey.Service';
import {environment} from '../../../../environments/environment';
import {DocumentModel} from '../../../Core/Models/Document.Model';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-render-stages-and-questions',
  templateUrl: './render-stages-and-questions.component.html',
  styleUrls: ['./render-stages-and-questions.component.scss']
})
export class RenderStagesAndQuestionsComponent implements OnInit {

  progressStyling = 'width:5%';
  progressStep = 100 / 11;
  applicationModel: ApplicationModel;
  filterCriteria: FilterCriteria;
  stageObjects: StageModel[];
  currentStage: StageModel;
  stageIndex: number;
  stage: StageModel;
  questionsPerStage: QuestionModel[][]; /* 2D array of question groups/arrays */
  currentQuestion: QuestionModel;
  questionIndex: number;
  bubbleMessage: string;
  showOkButton: boolean;
  endOfQuestions: boolean;
  endOfStages: boolean;
  endOfCurrentStage: boolean;
  candidateId: string;
  candidate: CandidateModel;
  answer: any;
  file: File;
  skipQuestion: boolean;
  candidateAnswerModel: CandidateAnswerModel;
  candidateStageProgressModel: CandidateStageProgressModel;
  stageValidated: boolean;
  companyId: string;
  resultsUpload: DocumentUploadModel;
  odysseyAssessmentLink: string;
  odysseyQuestionType: QuestionTypes.Odyssey;
  isOdysseyQuestion: boolean;
  isStageSkippable: boolean;
  documentModel: DocumentModel;
  purpose: string;
  childQuestion: string;
  documentUploaded: boolean;
  resumeApplication: boolean;
  odysseyInprogress: boolean;
  stageInProgress: CandidateStageProgressModel;
  stagesInProgress: CandidateStageProgressModel[];

  constructor(private questionsService: QuestionsAndAnswersService,
              private stageService: StagesService,
              private stagescandidatejobService: StagescandidatejobService,
              private  uploadService: DocumentsService,
              private route: ActivatedRoute,
              private router: Router,
              private applicationsService: ApplicationsService,
              private candidateService: CandidatesService,
              private authenticationService: AuthenticationService,
              private odysseyService: OdysseyService,
              public dom: DomSanitizer) { }

  ngOnInit(): void {

    this.applicationModel = new ApplicationModel();
    this.applicationModel.id = this.route.snapshot.params.id;
    // this.candidateId = 'a37ff347-9a65-d144-3406-f219776e2cb5';
    this.companyId = '1f5f7eb5-734f-b345-ca11-5f75b9286328';

    this.filterCriteria = new FilterCriteria();
    this.filterCriteria.ApplicationsKeys = Array<string>();
    this.filterCriteria.StageKeys = Array<string>();

    this.stageIndex = 0;
    this.questionIndex = 0;
    this.endOfQuestions = false;
    this.endOfStages = false;
    this.endOfCurrentStage = false;
    this.skipQuestion = true;
    this.stageValidated = false;
    this.isOdysseyQuestion = false;
    this.resumeApplication = false;
    this.odysseyInprogress = false;

    this.questionsPerStage = Array<QuestionModel[]>();

    this.fetchCandidate();
    this.fetchApplication();
  }

  /* Fetch Pre-required Data for initiating the Application process
  * */

  fetchCandidate(): void {
    const filterCriteria = new FilterCriteria();
    const user = this.authenticationService.getUserCredential();
    filterCriteria.EmailKey = user.username;

    this.candidateService.GetByFilterCriteria(filterCriteria).subscribe(results => {
      this.candidateId = results[0].id;
      this.candidate = results[0];
      this.odysseyAssessmentLink = 'https://www.odyssess.com/' +
        'WebContent/ng/applicant/login/partner-id/' + this.candidateId + '/' + results[0].identityNumber;
    });
  }

  fetchApplication(): void {
    this.applicationsService.GetById(Guid.parse(this.applicationModel.id)).subscribe( result => {
      this.applicationModel = result;
      console.log(result);
      this.fetchStages();
    });
  }

  fetchStages(): void {
    this.filterCriteria.ApplicationsKeys.push(this.applicationModel.id);
    this.stageService.GetByFilterCriteria(this.filterCriteria).subscribe(stages => {
      this.stageObjects = stages.filter(stage => stage.stageType !== StageTypeEnum.Interview &&
        stage.isActive && stage.isDeleted === false);
      console.log(stages);

      if (this.stageObjects.length ===  0) {
        this.showOkButton = true;
        this.bubbleMessage = this.applicationModel.title + ' does not have stages at the moment';
      } else {
        this.currentStage = this.stageObjects[0]; /* set initial stage */
        this.isStageSkippable = this.currentStage.skippable;

        this.stageObjects.forEach(stage => {
          this.questionsPerStage.push([]);
        });

        this.fetchApplicationProgress(this.stageObjects);

        // this.stageObjects.forEach(stage => {
        //   this.fetchQuestions(stage.id);
        // });

        // this.showOkButton = false;
      }

      // console.log(this.questionsPerStage);
    });
  }

  fetchQuestions(stageId: string): void {
    const filterCriteria = new FilterCriteria();
    filterCriteria.StageKeys = [stageId];

    this.questionsService.GetByFilterCriteria(filterCriteria).subscribe(results => {
      console.log(results);
      /* results === list of questions */
      if (results.length > 0) {
        const row = this.returnStageIndex(results[0].stageId);

        this.questionsPerStage[row] = results.filter(question =>
          question.questionType !== QuestionTypes.Interview && question.isActive && !question.isDeleted);

        this.currentQuestion = this.questionsPerStage[this.stageIndex][0]; /* 1st question */

        if (this.currentStage.stageType === StageTypeEnum.Odyssey) { /* For Resume Application */
          this.bubbleMessage = this.odysseyAssessmentLink;
        } else {
          this.bubbleMessage = this.currentQuestion.question;
        }

        this.showOkButton = false;
        this.updateProgressBar();
      } else if (results.length === 0) {
        this.showOkButton = true;
        this.bubbleMessage = this.stageObjects[this.stageIndex].name + ' stage does not have questions at the moment';
      }

    }, error => {
      console.log(error);
    });
  }

  /* Try to continue were the Candidate left of the Application */
  fetchApplicationProgress(stages: StageModel[]): void {
    /* fetch stages in progress */
    this.stagescandidatejobService.GetCandidateApplicationStages(this.candidateId, this.applicationModel.id).subscribe(response => {
      const stagesInProgress = new Array<CandidateStageProgressModel>();
      let localStageInProgress = new CandidateStageProgressModel();

      if (response.length > 0) {
        this.resumeApplication = true;
        this.stageInProgress = new CandidateStageProgressModel();
        /* Create Empty blue print for ordering, this helps with the size */
        response.forEach(stage => {
          stagesInProgress.push(null); /* This will match exact length of response */
        });

        /* Order the Stages */
        stages.forEach(stage => {
          const index = this.returnStageIndex(stage.id);
          if (response.filter(entry => entry.stageId === stage.id)[0]) {
            stagesInProgress[index] = response.filter(entry => entry.stageId === stage.id)[0];
          }
        });

        this.stagesInProgress = stagesInProgress;

        console.log(this.returnIncompleteStage(stages, stagesInProgress));
        // this.currentStage = this.stageObjects[this.returnStageIndex(stagesInProgress[stagesInProgress.length - 1].stageId)];
        this.currentStage = this.returnIncompleteStage(stages, stagesInProgress);
        this.stageIndex = this.stageObjects.indexOf(this.currentStage);

        // stagesInProgress.some(stage => {
        //   if (this.currentStage.id === stage.stageId) {
        //     localStageInProgress = stage;
        //     return;
        //   }
        // });

        for (let i = 0; i < stagesInProgress.length; i++ ) {
          if (stagesInProgress[0] === null) { /* If 1st stage is null */
            localStageInProgress = null;
            break;
          } else if (this.currentStage.id === stagesInProgress[i].stageId) {
            localStageInProgress = stagesInProgress[i];
            break;
          }
        }

        if (localStageInProgress && stagesInProgress.length > 1) {
          if (localStageInProgress.isPromoted &&
            localStageInProgress.progress === StageCandidateApplicationProgress.Complete) {
            this.stageValidated = true;
            this.showOkButton = false;
            this.endOfQuestions = true;
            this.endOfCurrentStage = true;
            this.endOfStages = false;
            this.odysseyInprogress = false;
            this.stageInProgress = localStageInProgress;
          } else if (!localStageInProgress.isPromoted &&
            (localStageInProgress.progress === StageCandidateApplicationProgress.InProgress ||
              localStageInProgress.progress === StageCandidateApplicationProgress.Incomplete ||
              localStageInProgress.progress === StageCandidateApplicationProgress.Complete)) {
            this.stageValidated = false;
            this.showOkButton = true;
            this.endOfQuestions = true;
            this.endOfCurrentStage = true;
            this.endOfStages = false;
            this.stageInProgress = localStageInProgress;

            if (localStageInProgress.progress === StageCandidateApplicationProgress.InProgress) {
              this.odysseyInprogress = true;
            }
          }

          this.prepareStageCommunication();
        } else {
          /* Continue as normal */
          this.stageObjects.forEach(stage => {
            this.fetchQuestions(stage.id);
          });
        }

      } else {
        /* Continue as normal */
        this.stageObjects.forEach(stage => {
          this.fetchQuestions(stage.id);
        });
      }

    });
  }

  returnIncompleteStage(stages: StageModel[], stagesInProgress: CandidateStageProgressModel[]): StageModel {
    let stage = new StageModel();

    console.log(stages);
    console.log(stagesInProgress);

    stage = this.doWeHaveNullStages(stagesInProgress);

    if (stage === null) { /* If we have all the stages without null */
      for (let i = 0; i < stages.length; i++) {
        if (!this.isStageComplete(stages[i], stagesInProgress)) { /* This returns the 1st occurance, a stage that is not complete */
          if (stagesInProgress.length > 1) {
            stage = stages[i - 1]; /* Return the stage before */
          } else {
            stage = stages[i]; /* Return the current stage */
          }
          break;
        }
      }
    }

    return stage;
  }

  doWeHaveNullStages(stagesInProgress: CandidateStageProgressModel[]): StageModel {
    if (stagesInProgress.length > 0) {
      for (let i = 0; i < stagesInProgress.length; i++) {
        if (stagesInProgress[i] === null && i !== 0) {
          return this.stageObjects[this.returnStageIndex(stagesInProgress[i - 1].stageId)]; /* Return the stage before */
        } else if (stagesInProgress[i] === null && i === 0) {
          return this.stageObjects[i];
        }
      }
    }

    return null;
  }

  /* Validate Scoring Stages
  *  How it works:-
  *  Make sure a all the stage questions are answered and valid or else disqualify/Fail Stage
  * */

  validateScoringStage(): void {
    this.stageValidated = false;
    this.candidateStageProgressModel = new CandidateStageProgressModel();
    this.candidateStageProgressModel.candidateId = this.candidateId;
    this.candidateStageProgressModel.applicationId = this.applicationModel.id;
    this.candidateStageProgressModel.stageId = this.currentStage.id;

    if (this.currentStage.isScoring && this.currentStage.stageType !== StageTypeEnum.Manual) {
      this.questionsService.GetAllCandidateStageAnswers(this.candidateId, this.currentStage.id).subscribe(list => {
        const filteredList = this.matchCandidateAnswersToStageQuestions(list);

        console.log(this.questionsPerStage[this.stageIndex].length, filteredList.length, list);
        console.log(this.questionsPerStage[this.stageIndex], filteredList, list);
        if (this.questionsPerStage[this.stageIndex].length === filteredList.length) {
          console.log('Questions list is equal to Answers list \n Good to go!');
          let countValidator = 0;
          /* Compare Expected Answers with Candidate Answers */
          for (let i = 0; i < this.questionsPerStage[this.stageIndex].length; i++) {
            for (let j = 0; j < filteredList.length; j++) {
              if (this.questionsPerStage[this.stageIndex][i].id === filteredList[j].questionId) {
                if (this.validateAnswer(this.questionsPerStage[this.stageIndex][i], filteredList[j])) {
                  countValidator++;
                }
              }
            }
          }

          if (countValidator === this.questionsPerStage[this.stageIndex].length) {
            this.candidateStageProgressModel.progress = StageCandidateApplicationProgress.Complete;
            this.candidateStageProgressModel.isPromoted = true;
            this.stageValidated = true;
            this.showOkButton = false;
            console.log('Stage Passed');
          } else if (countValidator < this.questionsPerStage[this.stageIndex].length) {
            this.candidateStageProgressModel.progress = StageCandidateApplicationProgress.Complete;
            this.candidateStageProgressModel.isPromoted = false;
            this.stageValidated = false;
            this.showOkButton = true;
            console.log('Stage Failed');
          } else if (countValidator === 0) {
            this.candidateStageProgressModel.progress = StageCandidateApplicationProgress.Incomplete;
            this.candidateStageProgressModel.isPromoted = false;
            this.stageValidated = false;
            this.showOkButton = true;
            console.log('Stage Failed');
          }
          this.updateStageProgressAndOutcome(this.candidateStageProgressModel);
          console.log(countValidator);
        }

        this.prepareStageCommunication();
      });
    } else {
      if (this.currentStage.stageType !== StageTypeEnum.Odyssey) {
        if (this.currentStage.stageType === StageTypeEnum.Manual) {
          this.candidateStageProgressModel.progress = StageCandidateApplicationProgress.InProgress;
          this.candidateStageProgressModel.isPromoted = false;
          this.stageValidated = true;
        } else {
          this.candidateStageProgressModel.progress = StageCandidateApplicationProgress.Complete;
          this.candidateStageProgressModel.isPromoted = true;
          this.stageValidated = true;
        }

        this.updateStageProgressAndOutcome(this.candidateStageProgressModel);
      }

      this.prepareStageCommunication();
    }
  }

  validateAnswer(definedAnswer: QuestionModel, candidateAnswer: CandidateAnswerModel): boolean {
    if (definedAnswer.questionType === QuestionTypes.MultipleSelect ||
      definedAnswer.questionType === QuestionTypes.Select ||
      definedAnswer.questionType === QuestionTypes.Text) {
      let defined = definedAnswer.answer.answer.split(',');
      defined = this.trimElementsInArray(defined);
      let candidate = candidateAnswer.answer.split(',');
      candidate = this.trimElementsInArray(candidate);
      const match = defined.filter(item => candidate.indexOf(item) > -1);
      if (match.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      if (definedAnswer.answer.answer.trim().toLowerCase() === candidateAnswer.answer.trim().toLowerCase()) {
        return true;
      } else {
        return false;
      }
    }
  }

  /* Update Candidate Stage/Application Progress Record
  * */

  updateStageProgressAndOutcome(candidateStageProgressModel: CandidateStageProgressModel): void {
    this.stagescandidatejobService.UpsertStageCandidateJob(candidateStageProgressModel).subscribe(response => {
      console.log(response);
    }, error => {
      console.log(error);
    });
  }

  async persistCandidateAnswer(answer: any): Promise<void> {
    this.candidateAnswerModel = new CandidateAnswerModel();

    if (this.currentQuestion && this.currentQuestion.question) {
      if (this.currentQuestion.questionType === QuestionTypes.Odyssey) {
        this.isOdysseyQuestion = true;
      } else {
        this.isOdysseyQuestion = false;
      }

      if (answer) {
        console.log(answer);
        if (this.currentQuestion.questionType === QuestionTypes.Upload) {
          this.documentUpload();
        } else {
          await this.persistAnswer(answer);
        }
      }
    }

  }

  async persistAnswer(answer: string): Promise<void> {
    this.candidateAnswerModel.answer = answer;
    this.candidateAnswerModel.candidateId = this.candidateId;

    /* Async Calls delays at UpsertDocument so this helps with upsetting for the right question */
    if (this.questionsPerStage[this.stageIndex][this.questionIndex - 1] &&
      this.questionsPerStage[this.stageIndex][this.questionIndex - 1].questionType === QuestionTypes.Upload) {
      this.candidateAnswerModel.question = this.childQuestion;
      this.candidateAnswerModel.questionId = this.questionsPerStage[this.stageIndex][this.questionIndex - 1].id;
    } else {
      this.candidateAnswerModel.question = this.currentQuestion.question;
      this.candidateAnswerModel.questionId = this.currentQuestion.id;
    }

    this.candidateAnswerModel.isDeleted = false;
    this.candidateAnswerModel.stageId = this.currentStage.id;

    const observable = this.questionsService.UpsertCandidateAnswer(this.candidateAnswerModel);
    const observableContent = await observable.toPromise();
    console.log(observableContent);
    // this.questionsService.UpsertCandidateAnswer(this.candidateAnswerModel).subscribe(response => {
    //   console.log(response);
    // }, error => { console.log(error); });
  }

  /* Traverse/Navigate Questions Backward and Forward
  * */

  async goToNextQuestion(): Promise<void> {
    await this.persistCandidateAnswer(this.answer);
    this.skipQuestion = true;
    this.documentUploaded = false;
    this.doubleCheckOdysseyStage();

    if (this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion) === -1) { /* if question is from prev stage */
      if (this.questionsPerStage[this.stageIndex].length > 0) { /* if current stage has Questions */
        if (this.currentStage.stageType === StageTypeEnum.Odyssey) {
          this.currentQuestion = this.questionsPerStage[this.stageIndex][0]; /* set 1st question */
          this.questionIndex = this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion);
          this.bubbleMessage = this.odysseyAssessmentLink;
          this.isOdysseyQuestion = true;
          this.updateProgressBar();
        } else {
          this.currentQuestion = this.questionsPerStage[this.stageIndex][0]; /* set 1st question */
          this.questionIndex = this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion);
          this.bubbleMessage = this.currentQuestion.question;
          this.updateProgressBar();
        }
      } else { /* if there are no questions */
        this.currentQuestion = new QuestionModel();
        this.showOkButton = true;
        this.bubbleMessage = this.stageObjects[this.stageIndex].name + ' stage does not have questions at the moment';
      }

    } else if (this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion) < this.questionsPerStage[this.stageIndex].length - 1 ) {

      if (this.currentStage.stageType === StageTypeEnum.Odyssey) { /* For Resume Application */
        this.currentQuestion = this.questionsPerStage[this.stageIndex][0]; /* set 1st question */
        this.questionIndex = this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion);
        this.bubbleMessage = this.odysseyAssessmentLink;
        this.isOdysseyQuestion = true;
        this.updateProgressBar();
      } else {
        if (this.canYouSkipCurrentStageQuestions()) {
          this.currentQuestion = this.questionsPerStage[this.stageIndex][this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion) + 1 ];
          this.questionIndex = this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion);
          this.bubbleMessage = this.currentQuestion.question;
          this.updateProgressBar();

          console.log('skip');
        } else {
          this.endOfCurrentStage = false;
          console.log('cannot skip');
        }
      }

      console.log(this.currentQuestion);
    } else if (this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion) !== -1 &&
      (this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion) === this.questionsPerStage[this.stageIndex].length - 1)) {
      console.log('end of questions');
      this.endOfCurrentStage = true;
      if (this.canYouSkipCurrentStageQuestions()) {
        this.questionIndex = this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion);
        this.endOfQuestions = true;

        this.updateProgressBar();

        /* Try to delay score validation */
        // setTimeout(() => {
        //   this.validateScoringStage();
        // }, 2000);
        this.validateScoringStage();

      } else {
        this.endOfCurrentStage = false;
      }
    }
  }

  goToPreviousQuestion(): void {
    this.skipQuestion = true;
    this.documentUploaded = false;
    if (this.endOfQuestions) {
      this.bubbleMessage = this.currentQuestion.question;
      this.endOfQuestions = false;
      this.endOfStages = false;

      this.doubleCheckOdysseyStage();
      this.updateProgressBar();
    } else {
      this.doubleCheckOdysseyStage();
      if (this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion) === 0 && this.stageIndex > 0) {
        this.goToPreviousStage();
      } else {
        if (this.questionsPerStage[this.stageIndex][this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion) - 1 ]) {
          this.currentQuestion = this.questionsPerStage[this.stageIndex][this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion) - 1 ];
        } else {
          this.currentQuestion = this.questionsPerStage[this.stageIndex][this.questionsPerStage[this.stageIndex].length - 1 ];
        }

        this.questionIndex = this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion);
        this.bubbleMessage = this.currentQuestion.question;
        this.updateProgressBar();
      }
    }
  }

  canYouSkipCurrentStageQuestions(): boolean {
    console.log(!this.currentStage.skippable, this.currentStage.isScoring, this.answer);
    if (this.currentStage.isScoring) {
      if (this.answer) { /* check for answer input */
        this.skipQuestion = true;
        this.answer = undefined;
        return true;
      } else { /* if no answer provided */
        this.skipQuestion = false;
        this.answer = undefined;
        return false;
      }
    } else { /* Non-Scoring */
      if (this.currentStage.skippable) {
        this.skipQuestion = true;
        this.answer = undefined;
        return true;
      } else {
        if (this.answer || this.documentModel) {
          this.skipQuestion = true;
          this.answer = undefined;
          this.documentModel = undefined;
          return true;
        } else {
          this.skipQuestion = false;
          this.answer = undefined;
          return false;
        }
      }
    }
  }

  /* Traverse/Navigate Stages Backward and Forward
  * */

  continueStage(): void {
    console.log(this.currentStage);
    this.stageIndex = this.stageObjects.indexOf(this.currentStage) + 1;
    this.currentStage = this.stageObjects[this.stageIndex];
    this.endOfQuestions = false;
    this.isStageSkippable = this.currentStage.skippable;
    this.endOfCurrentStage = false;

    console.log(this.endOfQuestions, this.showOkButton, this.endOfCurrentStage);
    if (this.currentStage.stageType === StageTypeEnum.Odyssey) {
      this.createOdysseyAssessment();

      if (this.resumeApplication) {
        this.currentQuestion = new QuestionModel();
        this.currentQuestion.questionType = QuestionTypes.Odyssey;
      }
    }

    if (this.resumeApplication) {
      /* Try To Continue as Normal */
      this.stageObjects.forEach(stage => {
        this.fetchQuestions(stage.id);
      });
    }
    console.log(this.stageIndex);
    if ((this.currentStage.stageType === StageTypeEnum.Odyssey) && (this.stageInProgress.stageId === this.currentStage.id)) {
      // if (this.stageInProgress.isPromoted) {
      //   this.goToNextQuestion();
      // }
    } else {
      this.goToNextQuestion();
    }

  }

  goToNextStage(): void {
    if (this.stageIndex < this.stageObjects.length - 1) {

      if (this.currentStage.stageType === StageTypeEnum.Manual) {
        this.stagescandidatejobService
          .GetStageCandidateJob(Guid.parse(this.candidateId), Guid.parse(this.currentStage.id)).subscribe(response => {
          if (response.progress === StageCandidateApplicationProgress.InProgress && !response.isPromoted) {

            this.stageValidated = false;
            this.showOkButton = true;
            this.endOfQuestions = true;
            this.endOfCurrentStage = true;
            this.endOfStages = false;

          } else if (response.progress === StageCandidateApplicationProgress.Complete && response.isPromoted) {
            this.continueStage();
          }
        });
      } else {
        this.continueStage();
      }
    } else if (this.stageIndex === this.stageObjects.length - 1) {

      console.log('end of stages');
      this.bubbleMessage = 'End Of ' + this.applicationModel.title + ' application process. Thank you for your Application.';
      this.endOfStages = true;
      this.endOfCurrentStage = true;

    }
  }

  goToPreviousStage(): void {
    if (this.stageIndex >= 0) {
      this.stageIndex = this.stageObjects.indexOf(this.currentStage) - 1;
      this.currentStage = this.stageObjects[this.stageIndex];
      this.isStageSkippable = this.currentStage.skippable;
      this.goToPreviousQuestion();
    }
  }

  /* Documents Upload Related functions
  * */

  deleteFileOnServer(): void {
    if (this.documentModel && this.documentModel.fileName) {
      // this.uploadService.DeleteFile(this.documentModel, environment.bucketName).subscribe(response => {
      //   console.log(response);
      // });
      this.documentModel = new DocumentModel();
    } else {
      this.documentModel = new DocumentModel();
    }
  }

  documentUpload(): void {
    if (this.currentQuestion.questionType === QuestionTypes.Upload) {
      if (this.file) {
        const formData = new FormData();
        formData.append('formFiles', this.file,
          this.candidate.name + '_' + this.candidate.identityNumber + '_' + this.file.name);
        const file = formData.get('formFiles');

        if (file) {
          this.uploadService.uploadFile(environment.bucketName, formData).subscribe(link => {

            if (this.documentModel && this.documentModel.fileName) { } else {
              this.documentModel = new DocumentModel();
            }
            this.documentModel.candidateId = this.candidateId;
            this.documentModel.fileName = this.candidate.name + '_' + this.candidate.identityNumber + '_' + this.file.name;
            this.documentModel.fileSize = this.file.size.toString();
            this.documentModel.fileType = this.file.type;
            this.documentModel.lastModified = this.file.lastModified;
            this.documentModel.question = this.childQuestion;
            this.documentModel.purpose = this.purpose;
            this.documentModel.url = link;

            this.uploadService.UpsertDocument(this.documentModel).subscribe(response => {
              console.log(response);
            }, error => {
              console.log(error, 'Something went wrong while trying to Upsert Document Information');
            });

            // this.persistAnswer(link);
            // this.file = null;
          });
        }
      }
    }
  }

  /* Odyssey Assessment Service Related
  * */

  doubleCheckOdysseyStage(): void {
    if (this.currentStage.stageType === StageTypeEnum.Odyssey) {
      this.isOdysseyQuestion = true;
    } else {
      this.isOdysseyQuestion = false;
    }
  }

  createOdysseyAssessment(): void {
    if (!this.didTheCandidateCompleteOdyssey(this.currentStage, this.stagesInProgress)) {
      const createAssessmentObject = new CreateAssignmentModel();

      createAssessmentObject.stageId = this.currentStage.id;
      createAssessmentObject.candidateId = this.candidateId;
      createAssessmentObject.firstName = this.candidate.name;
      createAssessmentObject.lastName = this.candidate.surname;
      createAssessmentObject.identityNumber = this.candidate.identityNumber;
      createAssessmentObject.email = this.candidate.email;
      createAssessmentObject.applicationId = this.applicationModel.id;
      createAssessmentObject.projectId = this.currentStage.projectId;
      createAssessmentObject.nationalityCode = 'za';

      this.createDefaultOdysseyEntryInDB();
      this.odysseyService.CreateAssignment(createAssessmentObject).subscribe(results => {
        console.log(results);
      }, error => {
        console.log(error);
      });
    } else {
      this.prepareStageCommunication();
    }
  }

  createDefaultOdysseyEntryInDB(): void {
    this.candidateStageProgressModel = new CandidateStageProgressModel();
    this.candidateStageProgressModel.candidateId = this.candidateId;
    this.candidateStageProgressModel.applicationId = this.applicationModel.id;
    this.candidateStageProgressModel.stageId = this.currentStage.id;
    this.candidateStageProgressModel.progress = StageCandidateApplicationProgress.InProgress;
    this.candidateStageProgressModel.isPromoted = false;

    console.log('Odyssey Entry is being created');
    this.updateStageProgressAndOutcome(this.candidateStageProgressModel);
    this.answer = 'Results Not Yet Available';
    this.currentQuestion = this.questionsPerStage[this.stageIndex][0]; /* set 1st question */
  }

  /* Collect Info from the (child components) -> where the candidate is answering the questions
  * */

  collectInputValue(value: any): void {
    this.answer = value;
  }

  collectUploadValue(value: any): void {
    this.file = value;
    console.log(this.file);
  }

  collectDocumentModel(value: any): void {
    this.documentModel = value;
  }

  collectFilePurpose(value: any): void {
    this.purpose = value;
  }

  collectCurrentInputQuestion(value: any): void {
    this.childQuestion = value;
  }

  collectDocumentUploadedStatus(value: any): void {
    this.documentUploaded = value;
  }

  /* Helper functions
  * */

  trimElementsInArray(array: string[]): any[] {
    for (let i = 0; i < array.length; i++) {
      array[i] = array[i].trim();
    }
    return array;
  }

  updateProgressBar(): void {
    this.progressStep = ((this.questionsPerStage[this.stageIndex].indexOf(this.currentQuestion) + 1) /
      (this.questionsPerStage[this.stageIndex].length)) * 100;
    this.progressStyling = `width: ${this.progressStep }%`;
  }

  prepareStageCommunication(): void {
    if (this.currentStage.qualifiedComm && this.currentStage.unqualifiedComm) {
      if (this.currentStage.stageType === StageTypeEnum.Odyssey) {
        this.odysseyStageCommunication();
      } else if (this.currentStage.stageType === StageTypeEnum.Manual && this.resumeApplication && this.stageInProgress) {

        if (this.stageInProgress.isPromoted && this.stageInProgress.progress === StageCandidateApplicationProgress.Complete) {
          this.bubbleMessage = this.currentStage.qualifiedComm;
        } else if (!this.stageInProgress.isPromoted && this.stageInProgress.progress === StageCandidateApplicationProgress.InProgress) {
          this.bubbleMessage = this.currentStage.qualifiedComm;
        } else if (!this.stageInProgress.isPromoted && this.stageInProgress.progress === StageCandidateApplicationProgress.Complete) {
          this.bubbleMessage = this.currentStage.unqualifiedComm;
        }

      } else {
        if (this.stageValidated) {
          this.bubbleMessage = this.currentStage.qualifiedComm;
        } else if (!this.stageValidated) {
          this.bubbleMessage = this.currentStage.unqualifiedComm;
        }
      }

    } else {
      if (this.currentStage.stageType === StageTypeEnum.Odyssey) {
        this.odysseyStageCommunication();
      } else {
        this.bubbleMessage = 'End of ' + this.stageObjects[this.stageObjects.indexOf(this.currentStage)].name + ' stage';
      }
    }
  }

  odysseyStageCommunication(): void {
    if (this.odysseyInprogress) {
      this.bubbleMessage = 'Thank You for taking the Odyssey Assessment,' +
        ' You will be able to continue with the Application once your results are ready. ' +
        'Click the link to continue the Assessment if you are not done. <a href=\"' + this.odysseyAssessmentLink + '\">Odyssey Assessment</a>';
    } else {
      this.stagescandidatejobService
        .GetStageCandidateJob(Guid.parse(this.candidateId), Guid.parse(this.currentStage.id)).subscribe( response => {
        if (response) {
          if (response.isPromoted && response.progress === StageCandidateApplicationProgress.Complete) {
            if (this.currentStage.qualifiedComm) {
              this.bubbleMessage = this.currentStage.qualifiedComm;
            } else {
              this.bubbleMessage = 'Congratulations for making it past the Odyssey stage, you can now contunue with your application';
            }
          } else if (!response.isPromoted && response.progress === StageCandidateApplicationProgress.InProgress) {
            this.bubbleMessage = 'Thank You for taking the Odyssey Assessment,' +
              ' You will be able to continue with the Application once your results are ready. ' +
              'Click the link to continue the Assessment if you are not done. <a href=\"' + this.odysseyAssessmentLink + '\">Odyssey Assessment</a>';
          } else if (!response.isPromoted && response.progress === StageCandidateApplicationProgress.Complete) {
            if (this.currentStage.unqualifiedComm) {
              this.bubbleMessage = this.currentStage.unqualifiedComm;
            } else {
              this.bubbleMessage = 'Sorry you failed to meet the minimum requirements for the Odyssey Assessment.';
            }
          }
        } else {
          this.bubbleMessage = 'Stage progress not available';
        }
      }, error => {
        console.log(error);
      });
    }
  }

  matchCandidateAnswersToStageQuestions(list: CandidateAnswerModel[]): CandidateAnswerModel[] {
    const filterList = Array<CandidateAnswerModel>();

    for (let i = 0; i < list.length; i++) {
      for (let j = 0; j < this.questionsPerStage[this.stageIndex].length; j++) {
        if (list[i].questionId === this.questionsPerStage[this.stageIndex][j].id) {
          filterList.push(list[i]);
        }
      }
    }

    return filterList;
  }

  returnStageIndex(stageId: string): number {
    for (let i = 0; i < this.stageObjects.length; i++) {
      if (this.stageObjects[i].id === stageId) {
        return i;
      }
    }
  }

  isStageComplete(stage: StageModel, stagesInProgress: CandidateStageProgressModel[]): boolean {
    let state = false;

    stagesInProgress.forEach(stageIP => {
      if (stage.id === stageIP.stageId) {
        if (stageIP.progress === 2 && stageIP.isPromoted === true) {
          state = true;
        } else if (stageIP.progress === 1 && stageIP.isPromoted === false) {
          state = false;
        } else {
          state = false;
        }

        return;
      }
    });

    return state;
  }

  didTheCandidateCompleteOdyssey(stage: StageModel, stagesInProgress: CandidateStageProgressModel[]): boolean {
    let state = false;

    stagesInProgress.forEach(stageIP => {
      if (stageIP) {
        if (stage.id === stageIP.stageId) {
          if (stageIP.progress === 2 && stageIP.isPromoted === true) {
            this.stageValidated = true;
            this.showOkButton = false;
            this.endOfQuestions = true;
            this.endOfCurrentStage = true;
            this.endOfStages = false;
            this.odysseyInprogress = false;
          } else if (stageIP.isPromoted === false) {
            this.stageValidated = true;
            this.showOkButton = true;
            this.endOfQuestions = true;
            this.endOfCurrentStage = true;
            this.endOfStages = false;
          }
          this.resumeApplication = false;
          this.stageInProgress = stageIP;
          state = true;
          return;
        }
      }
    });

    return state;
  }

  /* Closing off the Application process
  * */

  completeApplicationAndGoHome(): void {
    /* Route to main  */
    this.router.navigate(['applications']);
  }

}
