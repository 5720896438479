import {BaseModel} from './Base.Model';
import {StageCandidateApplicationProgress} from '../Enums/Progress.Enums';
import {StageTypeEnum} from '../Enums/StageType.Enum';

export class StageModel extends BaseModel {
  name: string;
  applicationId: string;
  isActive: boolean;
  isScoring: boolean;
  orderby: number;
  skippable: boolean;
  lastEditBy: string;
  qualifiedComm: string;
  unqualifiedComm: string;
  showComms: boolean;
  stageType: StageTypeEnum;
  progress: StageCandidateApplicationProgress;
  projectId: number;
  isDeleted: boolean;
}
