import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';

@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss']
})
export class VerifyUserComponent implements OnInit {

  userVerificationForm: FormGroup;
  password: FormControl;
  verificationId: any;
  tokenIsValid: any;
  verify = '';
  heading: string;
  message: string;

  constructor( private authenticationService: AuthenticationService,
               private router: Router,
               private route: ActivatedRoute) { }

  ngOnInit(): void {



     this.verify  = localStorage.getItem('verify');
      if (this.verify === 'register'){
        this.heading = 'VERIFY';
        this.message = 'Registration successful. Please check your email inbox for a link to confirm your email address to enable you to login.';
      }else{
        this.heading = 'RESET PASSWORD';
        this.message = 'Reset Password successful. Please check your email inbox for a link to  be able to change your password.';
      }





    /*   this.route.params.subscribe(params => {
         this.verificationId = params['id'];
       });
      /!* if (!this.verificationId) {
         console.log('verification id is invalid/missing');
         this.router.navigate(['./error']);
       } else {
          this.authenticationService.getRegistrationToken(this.verificationId)
            .subscribe(data => {
              this.tokenIsValid = data;
            }, error => {
              console.log('unsuccessful email verification');
              this.router.navigate(['./error']);
            });*!/*/

    // }
    this.createFormControls();
    this.createForm();
  }

  createFormControls() {
    this.password = new FormControl('', Validators.required);
  }

  createForm() {
    this.userVerificationForm = new FormGroup({
      password: this.password
    });
  }
  verifyMethod() {

    this.authenticationService.verifyEmail(this.verificationId, this.password).subscribe(data => {
      console.log('email verification successful');
      this.router.navigate(['./login', {message: 'email verification successful'}]);
    }, response => {
      console.log('unsuccessful email verification');
      this.router.navigate(['./error']);
    });
  }

}
