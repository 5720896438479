<app-navbar></app-navbar>

<div class="loading-animation" *ngIf="!isLoaded">
  <img src="../../../../assets/logo/loading-gears.gif" alt="">
</div>

<div class="main-container" *ngIf="isLoaded">
  <div class="big-banner">
    <img [src]="this.application.bannerImage" alt="" class="big-banner">
  </div>

  <div class="application-mini-info">
    <div class="leftbox">
      <div class="applicationName">{{this.application.title}}</div>
      Posted: <strong>{{application.dateCreated | date}}</strong> <br>
      <button *ngIf="applicationClosed(application) === true" class="button apply-btn" type="button" (click)="goToApplicationQuestions(this.application.id)" >Apply</button>
      <button *ngIf="applicationClosed(application) === false" class="button close-apply-btn" type="button">Application Closed</button>
    </div>
    <div class="rightbox">
      Available: <strong>{{this.application.availableDate | date}}</strong> <br>
      Expires: <strong>{{this.application.expiryDate | date}}</strong> <br>

      <div class="socialMedia">
        <a href="{{socialLink}}" target="_blank" (click)="shareToSocials('facebook')"><img src="../../../../assets/icons/socials/facebook.svg" class="social-icons" alt=""></a>
        <a href="{{socialLink}}" target="_blank" (click)="shareToSocials('twitter')"><img src="../../../../assets/icons/socials/twitter.svg" class="social-icons" alt=""></a>
        <a href="{{socialLink}}" target="_blank" (click)="shareToSocials('linkedin')"><img src="../../../../assets/icons/socials/linkedin.svg" class="social-icons" alt=""></a>
        <button type="button" class="linkbtn" (click)="modalService.open('modal-1')">
          <img src="../../../../assets/icons/socials/link.svg" (click)="shareToSocials('link')" class="social-icons" alt="">
        </button>

        <jw-modal id="modal-1">
          <div class="header-row">
            <div class="header">{{this.application.title}}</div>
            <button class="close" (click)="modalService.close('modal-1')">
              <img src="../../../../assets/icons/close.svg" alt="">
            </button>

          </div>
              <div class="modal-body">
                <form [formGroup]="linkForm" >
                  <div class="form-group">
                    <input type="text" class="form-control" formControlName="sharableLink" id="linkBox" (focus)="$event.target">
                  </div>
                </form>
                <br>
              </div>

        </jw-modal>

      </div>
    </div>
  </div>


  <hr>

  <div class="application-main-info">
    <article [innerHTML]="dom.bypassSecurityTrustHtml(this.application.longDescription)" >
    </article>
  </div>
</div>

<footer *ngIf="isLoaded">
  <div class="hazie-logo">
    <a href="https://info.hazie.co.za" target="_blank" style="text-decoration: none;">
      <img height="50" width="200" src="../../../../assets/logos/powered-by-hazie-color-transparent.png" alt="">
    </a>
  </div>
</footer>
