<div class="container background">
  <div class="left-box">
    <div class="company-logo">
      <a routerLink="/login" title="oldmutual">
        <img  class="logo" src="../../../../assets/logos/Old-Mutual.png" alt="Card image cap">
      </a>
    </div>
    <div class="powered-by-hazie">
      <a href="https://info.hazie.co.za" target="_blank" title="hazie">
        <img  class="logo" src="../../../../assets/logos/powered-by-hazie-color-transparent.png" alt="Card image cap">
      </a>
    </div>
  </div>

  <div class="right-box">
    <div class="card">
      <div class="card-header">
        <div class="company-logo">
          <a routerLink="/login" title="oldmutual">
            <img  class="logo" src="../../../../assets/logos/Old-Mutual.png" alt="Card image cap">
          </a>
        </div>

        <div class="header-text">
          <h1>SIGN IN</h1>
          <small>TO APPLY</small>
        </div>
      </div>

      <div class="card-body">
        <div class="form-container">
          <form [formGroup]="loginForm" (ngSubmit)="login()">

            <input
              [(ngModel)]="model.email"
              formControlName="email"
              placeholder="EMAIL ADDRESS"
              type="text"
              id="username"
              name="username"/>

            <input
              [(ngModel)]="model.password"
              formControlName="password"
              placeholder="PASSWORD"
              type="password"
              id="password"
              name="password"/>
            <input type="submit" value="LOGIN">

          </form>

        </div>
      </div>

      <div class="card-footer">
        <div class="errors">
          <div class="error-messages" *ngIf="password.touched">
            <div *ngIf="password.invalid && password.dirty">* Password needs to be at least 6 characters </div>
            <div *ngIf="!password.value">* Password can't be blank</div>
          </div>

          <div class="error-messages" *ngIf="email.touched">
            <div *ngIf="email.invalid && email.dirty">* Email needs to be something like me@example.com </div>
            <div *ngIf="!email.value">* Email can't be blank</div>
          </div>

          <div class="error-messages">
            <div *ngIf="submitted">{{errorMessages}}</div>
          </div>
        </div>

        <div class="forgot-password">
          <a routerLink="/forgotPassword">
            Forgot your password?
          </a>
        </div>
        <hr>
        <div class="sign-up-login">
          Don't have an account? <a routerLink="/register">Sign Up</a>
        </div>
      </div>
    </div>
  </div>
</div>
