import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {QuestionModel} from '../../../../../Core/Models/Question.Model';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {QuestionsAndAnswersService} from '../../../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';

@Component({
  selector: 'app-input-multi-select',
  templateUrl: './input-multi-select.component.html',
  styleUrls: ['./input-multi-select.component.scss']
})
export class InputMultiSelectComponent implements OnInit {

  @Input() question: QuestionModel;
  @Input() candidateId: string;
  @Output() answer: EventEmitter<string> = new EventEmitter<string>();
  dropdownList = [];
  selectedItems = [];
  items = [];
  dropdownSettings: IDropdownSettings;
  value = '';
  inputForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private questionsService: QuestionsAndAnswersService) { }

  ngOnInit(): void {

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.initiateForm();
    this.prefillDropDownList();
    this.prefillSelectedItems();
  }

  initiateForm(): void {
    this.inputForm = this.formBuilder.group({
      formValue: ['', [Validators.required]]
    });
  }
  /* -Mark form as touched- */
  markFormFieldsAsTouched(): void {
    this.formValue.markAsTouched();
  }

  /* Form Getters */
  get formValue(): AbstractControl {
    return this.inputForm.get('formValue');
  }

  prefillDropDownList(): void {
    this.dropdownList = this.question.options.split(',');
  }

  prefillSelectedItems(): void {
    this.getCandidateQuestionAnswer();
  }

  getCandidateQuestionAnswer(): void {
    this.questionsService.GetCandidateAnswer(this.candidateId, this.question.id).subscribe(answer => {
      console.log(answer);
      if (answer) {
        this.selectedItems = answer.answer.split(',');
        this.items = this.selectedItems;
        this.value = answer.answer;
        this.answer.emit(this.value);
      }
    });
  }

  onItemSelect(item: any): void {
    this.markFormFieldsAsTouched();
    if (this.items.indexOf(item) !== -1) {
      this.value = this.items.join();
      this.answer.emit(this.value);
    } else {
      this.items.push(item);
      this.value = this.items.join();
      this.answer.emit(this.value);
    }
  }

  onDeSelect(item: any): void {
    this.items.splice(this.items.indexOf(item), 1);
    this.value = this.items.join();
    this.answer.emit(this.value);
  }

  onDeSelectAll(items: any): void {
    this.items = [];
    this.value = undefined;
    this.answer.emit(this.value);
  }

  onSelectAll(items: any): void {
    this.markFormFieldsAsTouched();
    if (items.length === 0) {
      this.value = undefined;
      this.answer.emit(this.value);
    } else {
      this.value = items.join();
      this.items = items as Array<any>;
      this.answer.emit(this.value);
    }
  }

}
