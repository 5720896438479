<div class="background">
  <div class="container">
    <div class="company-logo">
      <a routerLink="/login">
        <img  class="logo" src="../../../../assets/logos/Old-Mutual.png" alt="Card image cap">
      </a>
    </div>
    <div class="text">
      Site under maintenance <br> Sorry for any inconveniences caused
    </div>
    <div class="powered-by-hazie">
      <a href="https://hazie.co.za" target="_blank">
        <img  class="logo" src="../../../../assets/logos/powered-by-hazie-color-transparent.png" alt="Card image cap">
      </a>
    </div>
  </div>
</div>
