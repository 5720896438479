import {BaseModel} from './Base.Model';
import {Gender} from './Gender.Model';
import {Guid} from 'guid-typescript';
import {LanguageEnum} from '../Enums/Language.Enum';
import {PopulationGroupEnum} from '../Enums/PopulationGroup.Enum';
import {DisabilityEnum} from '../Enums/Disability.Enum';

export class CandidateProfileModel extends BaseModel{
  userName: string;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  gender: Gender;
  currentCity: string;
  isActive: boolean;
  candidateId: string;
  idNumber: any;
  homeLanguage: LanguageEnum;
  populationGroup: PopulationGroupEnum;
  disability: DisabilityEnum;
  contactDetails: any;
  cellphoneNumber: any;
  email: string;
  isNewAccount: boolean;
}
