import {BaseModel} from './Base.Model';
import {AnswerModel} from './Answer.Model';
import {QuestionTypes} from '../Enums/QuestionTypes.Enum';
import {OptionTypes} from '../Enums/OptionTypes.Enum';

export class QuestionModel extends BaseModel {
  question: string;
  questionType: QuestionTypes;
  options: any;
  isActive: boolean;
  answer: AnswerModel;
  stageId: string;
  dateCreated: Date;
  orderby: number;
  isDeleted: boolean;
  jobId: string;
  selectItems = [];
  optionType: OptionTypes;
}
