export enum Industry {
  InformationTechnology = 1,
  Engineering,
  Administration,
  Manufacturing,
  HealthCare,
  Finance,
  Retail,
  Accounting,
  Construction,
  Media
}
