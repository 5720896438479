import {Component, OnInit} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Hazie';

  constructor(private titleService: Title, private metaService: Meta) {}

  ngOnInit(): void {
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'Hazie, Botsza, Chatbot, AI'},
      {name: 'description', content: 'Hazie is your Digital Career Placement platform that aims to assist you close the gap between you and your next big move.'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }
}
