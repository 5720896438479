import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {QuestionModel} from '../../../../../Core/Models/Question.Model';
import {QuestionsAndAnswersService} from '../../../../../Services/QuestionsAndAnswersService/QuestionsAndAnswers.Service';
import {CandidateAnswerModel} from '../../../../../Core/Models/CandidateAnswer.Model';
import {Subject} from 'rxjs';
import {CandidateProfileModel} from '../../../../../Core/Models/CandidateProfile.Model';
import {CandidatesprofileService} from '../../../../../Services/ProfilesService/CandidateService/candidatesprofile.service';
import {Router} from '@angular/router';
import {StageModel} from '../../../../../Core/Models/Stage.Model';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss']
})
export class InputTextComponent implements OnInit, OnDestroy {
  @Input()
  isProfile: boolean;
  @Input()
  onboardQuestions: any[];
  @Input()
  renderedQuestion: QuestionModel;
  @Input()
  candidateId: string;
  @Input()
  onNextButtonClicked: Subject<boolean>;
  @Output()
  validate: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  isPromoted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  profile: CandidateProfileModel;
  @Input()
  indexOfLoadingQuestion: number;
  @Input()
  currentStage: StageModel;
  errorM: boolean;
  isError: boolean;
  profileAnswer;
  answerCandidate = new CandidateAnswerModel();

  constructor(private questionsService: QuestionsAndAnswersService,
              private candidatesprofileService: CandidatesprofileService,
              private router: Router) {

  }
 async ngOnInit() {

    console.log('current stage', this.currentStage)
    if(this.isProfile === false){
      this.onNextButtonClicked.subscribe(value => {
        if (this.validateAnswer()) {
          this.onNextButton();
        }
      });

    }
    if(this.isProfile === true){
      this.onNextButtonClicked.subscribe(value => {

         this.upsertAnswerForProfile();

      });
    }

  }

  onNextButton(): void{
   this.upsertAnswerForQuestion();
  }
  async upsertAnswerForProfile() {


    switch (this.indexOfLoadingQuestion) {
      case 0:
         this.profile.cellphoneNumber = this.profileAnswer;
         this.validateMobileOnboard();
         this.profileAnswer = null;
         break;
      case 1:
         this.profile.contactDetails = this.profileAnswer;
         this.validateMobileOnboard();
         this.profileAnswer = null;
         break;
      case 2:
        this.profile.currentCity = this.profileAnswer;
        this.profileAnswer = null;

        break;
      case 3:
        this.profile.dateOfBirth = this.profileAnswer;
        this.profileAnswer = null;
        break;
      case 4:
        this.profile.disability = this.profileAnswer;
        this.profileAnswer = null;
        break;
      case 5:
        this.profile.gender = this.profileAnswer;
        this.profileAnswer = null;
        break;
      case 6:
        this.profile.homeLanguage = this.profileAnswer;
        this.profileAnswer = null;
        break;


      case 7:
        this.profile.populationGroup = this.profileAnswer;
        this.profileAnswer = null;
        console.log(this.profile);
        break;
      default:
        this.profile.isNewAccount = false;
        console.log(this.profile);

        const observableCandidateProfile =  this.candidatesprofileService.Upsert(this.profile);
        await observableCandidateProfile.
        toPromise().then( res => {
          this.router.navigate(['./applications']);
          },
          msg => {
            this.router.navigate(['./error']);
          });
        break;
    }
  }

  upsertAnswerForQuestion(): void {



    this.answerCandidate.questionId = this.renderedQuestion.id;
    this.answerCandidate.stageId = this.renderedQuestion.stageId;
    this.answerCandidate.candidateId = this.candidateId;
    this.questionsService.UpsertCandidateAnswer(this.answerCandidate)
      .subscribe(x => {

      }, error =>  {

      });
  }

  validateAnswer(): boolean {
    if (this.answerCandidate.answer === '') {
      this.validate.emit(false);
      return false;
    }
    //Checks if current stage is a scoring stage then checks for the expected answer and candidate answer
    if(this.currentStage.isScoring === true){
      if (this.answerCandidate.answer.includes(this.renderedQuestion.answer.answer)){
        this.isPromoted.emit(true);
      } else
      {
        this.isPromoted.emit(false);
      }
    }else{
      this.isPromoted.emit(true);
    }
    this.validate.emit(true);
    return true;
  }

  validateProfile(): boolean {
    if (this.profileAnswer === '') {
      this.validate.emit(false);
      return false;
    }
    this.validate.emit(true);
    return true;

  }

  validateMobileOnboard() {
    // tslint:disable-next-line:max-line-length
    const ex = /^(\+27|0)[6-8][0-9]{8}$/;
    if (ex.test(this.profileAnswer) === false) {
      this.errorM = true;
    } else {
      this.errorM = false;
    }
  }
  validateID() {
    // tslint:disable-next-line:max-line-length
    const ex = /^(((\d{2}((0[13578]|1[02])(0[1-9]|[12]\d|3[01])|(0[13456789]|1[012])(0[1-9]|[12]\d|30)|02(0[1-9]|1\d|2[0-8])))|([02468][048]|[13579][26])0229))(( |-)(\d{4})( |-)(\d{3})|(\d{7}))/;
    if (ex.test(this.profileAnswer.answer) === false || this.profileAnswer.length !== 13) {
      this.isError = true;
    } else {

      this.isError = false;
    }
  }

  ngOnDestroy(): void {
  }

}
