import {BaseModel} from './Base.Model';

import {Guid} from 'guid-typescript';
import {LocationApplicationModel} from './LocationApplication.Model';
import {categoryEnum} from '../Enums/Category.Enum';

export class ApplicationModel extends BaseModel{
  title: string;
  shortDescription: string;
  longDescription: string;
  salary: number;
  isDeleted: boolean;
  availableDate: Date;
  expiryDate: Date;
  isActive: boolean;
  dateCreated: Date;
  deleteDate: Date;
  location = new LocationApplicationModel();
  industry: string;
  companyId: string;
  orderBy: number;
  logoImage: string;
  bannerImage: string;
  category: categoryEnum;
}
