<div class="container background" *ngIf="isLoaded === true">

  <div class="container--left-view">
    <div class="container--left-view-company-logo">
      <img  class="hazie-logo-left" src="../../../../assets/logos/Hazie-logo-white.png" alt="Card image cap">
    </div>
  </div>

  <div class="container--right-view">
    <div class="container--right-view-card">
      <div class="container--right-view-card-progress">
        <div class="container--right-view-card-progress-back" *ngIf="showBackButton" (click)="onBackButton()">
          <img class="onArrowButton" src="../../../../assets/images/arrow.svg" >
          <div class="onBackButton"> BACK</div>
        </div>
        <div class="container--right-view-card-progress-back" *ngIf="!this.showBackButton"  >
          <div class="onBackButton" > </div>
        </div>

        <div class="container--right-view-card-progress-bar">
          <span class="container--right-view-card-progress-bar-fill"  style="{{progressStyling}}"></span>
        </div>
      </div>

      <div class="container--right-view-card-speech">
        <div class="container--right-view-card-speech-bubble">
          <h5>{{this.question}}</h5>
        </div>
      </div>


      <div class="container--right-view-card-logo">
        <img  class="hazie-logo-middle" src="../../../../assets/logo/icon.png" alt="Card image cap">
      </div>

      <div class="container--right-view-card-questions">
        <div class="container--right-view-card-questions-question">
          <app-input-text *ngIf="isLoaded===true" [isProfile]="true" class="step-inputs" [profile]="this.candidateProfile" [onNextButtonClicked]="onNextButtonClicked"   [indexOfLoadingQuestion]="indexOfQuestion"></app-input-text>
        </div>

      </div>

      <div class="container--right-view-card-next">
        <button type="submit" (click)="nextQuestion()" class="button">Next</button>
      </div>
    </div>
  </div>

</div>
