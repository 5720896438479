import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {ApplicationModel} from '../../../../Core/Models/Application.Model';
import {LocationApplicationModel} from '../../../../Core/Models/LocationApplication.Model';
import {CandidateModel} from '../../../../Core/Models/CandidateModelModell.Model';
import {FilterCriteria} from '../../../../Core/Models/FilterCriteria';
import {AuthenticationService} from '../../../../Services/AuthenticationService/authentication.service';
import {CandidatesService} from '../../../../Services/CandidatesService/candidates.service';
import {ApplicationsService} from '../../../../Services/ApplicationsService/Applications.Service';
import {StagesService} from '../../../../Services/StagesService/Stages/stages.service';
import {StagescandidatejobService} from '../../../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {Guid} from 'guid-typescript';
import {DocumentsService} from '../../../../Services/DocumentsService/documents.service';
import {CandidateStageProgressModel} from '../../../../Core/Models/CandidateStageProgress.Model';
import {ApplicationStagesModel} from '../../../../Core/Models/ApplicationStages.Model';
import {StageModel} from '../../../../Core/Models/Stage.Model';
import {DocumentUploadModel} from '../../../../Core/Models/DocumentUpload.Model';
import {Industry} from '../../../../Core/Models/Industry';
import {Router} from '@angular/router';
import {ModalService} from '../../../_modal';

@Component({
  selector: 'app-my-applications',
  templateUrl: './my-applications.component.html',
  styleUrls: ['./my-applications.component.scss']
})
export class MyApplicationsComponent implements OnInit, OnDestroy {



  applications: ApplicationModel[];
  app: ApplicationModel;
  candidate: CandidateModel;
  candidateStagesProgress: CandidateStageProgressModel[];
  public filterCriteria: FilterCriteria;
  applicationStagesProgrgess: ApplicationStagesModel[];
  stages: StageModel[];
  isLoaded = false;
  resultDoc: DocumentUploadModel;


  constructor(private authenticationService: AuthenticationService,
              private router: Router,
              private candidateService: CandidatesService,
              private  applicationsService: ApplicationsService,
              private stageService: StagesService,
              private stagescandidatejobService: StagescandidatejobService,
              private documentsService: DocumentsService,
              public modalService: ModalService) {




  }

  async ngOnInit() {
    this.applicationStagesProgrgess = new Array<ApplicationStagesModel>();
    this.applications = new Array<ApplicationModel>();
    this.stages = new Array<StageModel>();
    this.candidateStagesProgress = new Array<CandidateStageProgressModel>();
    this.candidate = new CandidateModel();
    this.resultDoc = new DocumentUploadModel();

    const userDetails = this.authenticationService.getUserCredential();
    if (userDetails.username) {
      this.filterCriteria = new FilterCriteria();
      this.filterCriteria.EmailKey = userDetails.username;
      this.getCandidate();
    }
  }

  async getCandidate(){

    const observableCandidates =  this.candidateService.GetByFilterCriteria(this.filterCriteria);
    const resultCandidate = await observableCandidates.toPromise();
    this.candidate = resultCandidate[0];
    await this.getApplications(this.candidate.id);

  }

  async getApplications(candidateid: string){


    //Get Applications
    const observableApplications = this.stagescandidatejobService.GetCandidateApplications(Guid.parse(candidateid));
    const resultApplications = await observableApplications.toPromise();
    // Find Unique Guid for applications Guid
    const uniqueArray = Array.from(new Set(resultApplications));
    // Assign Unique Guids to Applications keys
    this.filterCriteria.ApplicationsKeys = uniqueArray;

    //Get Applicatins
    const observableMyApplications =  this.applicationsService.GetByFilterCriteria(this.filterCriteria);
    const resultMyApplications = await observableMyApplications.toPromise();
    this.applications = resultMyApplications;


    //Loop through each application
    for (let i = 0; i < this.applications.length; i++) {
      let temp = new ApplicationStagesModel();

      let resultUrl = '';

      const observableDoc = this.documentsService.GetById(Guid.parse(this.applications[i].bannerImage));
       observableDoc.toPromise().then(res => {
        this.resultDoc = res;

        if (res) {
          this.documentsService.GetFileURL(res.fileName).subscribe(finalResult => {
            resultUrl = finalResult;
          });
        }
      },
        err => {
        resultUrl = '/assets/images/hazie-banner.jpg';
        console.log(err);
        });

      // const observableUrl = this.documentsService.GetFileURL(resultDoc.fileName);
      // const resultUrl = await observableUrl.toPromise();
      this.applications[i].bannerImage = resultUrl;
      this.filterCriteria.EmailKey = '';
      const stageFilterCriteria = new FilterCriteria();
      stageFilterCriteria.ApplicationsKeys = [];
      stageFilterCriteria.ApplicationsKeys.push( this.applications[i].id);
      const observableStages = this.stageService.GetByFilterCriteria(stageFilterCriteria);
      const resultStages = await observableStages.toPromise();
      this.stages = resultStages;
      for (let j = 0; j < this.stages.length; j++){
        console.log(this.candidateStagesProgress)
        const observableStageComplete = this.stagescandidatejobService.GetStageCandidateJob(Guid.parse(candidateid), Guid.parse(this.stages[j].id))
        const resultStageComplete = await observableStageComplete.toPromise();
        let  candidateProgress = new CandidateStageProgressModel();
        candidateProgress = resultStageComplete;
        if (candidateProgress){
           candidateProgress.name = this.stages[j].name;
           const indexOfProgress = this.candidateStagesProgress.findIndex(x => x.name === candidateProgress.name);
           if(indexOfProgress === -1){
              temp.stages.push(candidateProgress);
           }
        }else{
          console.log(this.stages[j].name)
          let  candidateStageProgressModel = new CandidateStageProgressModel();
          candidateStageProgressModel.name = this.stages[j].name;
          candidateStageProgressModel.progress = 1;
          temp.stages.push(candidateStageProgressModel);
        }


      }
      temp.title = this.applications[i].title;
      temp.id = this.applications[i].id;
      temp.bannerImage = this.applications[i].bannerImage;
      temp.logoImage = this.applications[i].logoImage;
      temp.industry = this.applications[i].industry;


      const indexOfApplication = this.applicationStagesProgrgess.filter( x => x.title === temp.title);

      if (indexOfApplication.length === 0) {
        this.applicationStagesProgrgess.push(temp);
      }

    }
    console.log(this.applicationStagesProgrgess,'dfsidofjsodj');

    this.isLoaded = true;


  }

  getIndustry(industry) {
    return Industry[industry];
  }


  toApplication(application: ApplicationStagesModel) {
    this.router.navigate(['candidate-questions', application.id]);
  }

  ngOnDestroy(): void {
  }
}
