import { Component, OnInit } from '@angular/core';
import {ModalService} from '../../_modal';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {UserCredentialModel} from '../../../Core/Models/UserCredentialModel';
import {ApplicationModel} from '../../../Core/Models/Application.Model';
import {CandidateModel} from '../../../Core/Models/CandidateModelModell.Model';
import {CandidateStageProgressModel} from '../../../Core/Models/CandidateStageProgress.Model';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {ApplicationStagesModel} from '../../../Core/Models/ApplicationStages.Model';
import {StageModel} from '../../../Core/Models/Stage.Model';
import {DocumentUploadModel} from '../../../Core/Models/DocumentUpload.Model';
import {CandidatesService} from '../../../Services/CandidatesService/candidates.service';
import {ApplicationsService} from '../../../Services/ApplicationsService/Applications.Service';
import {StagesService} from '../../../Services/StagesService/Stages/stages.service';
import {StagescandidatejobService} from '../../../Services/StagesService/StagesCandidateJob/stagescandidatejob.service';
import {DocumentsService} from '../../../Services/DocumentsService/documents.service';
import {Guid} from 'guid-typescript';
import {Industry} from '../../../Core/Models/Industry';
import {StageCandidateApplicationProgress} from '../../../Core/Enums/Progress.Enums';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  currentChoice = 'applications';
  authentication: boolean;
  user: UserCredentialModel;
  isLoaded: boolean;
  applications: ApplicationModel[];
  app: ApplicationModel;
  candidate: CandidateModel;
  candidateStagesProgress: CandidateStageProgressModel[];
  public filterCriteria: FilterCriteria;
  applicationStagesProgrgess: ApplicationStagesModel[];
  stages: StageModel[];
  showMessage: boolean;
  stageNames: string[];
  candidateStages: CandidateStageProgressModel[];
  stagesForSvg: CandidateStageProgressModel[];
  currentApplicationStage: ApplicationStagesModel;

  resultDoc: DocumentUploadModel;

  constructor(public modalService: ModalService, private router: Router, private authenticationService: AuthenticationService,
              private candidateService: CandidatesService,
              private  applicationsService: ApplicationsService,
              private stageService: StagesService,
              private stagescandidatejobService: StagescandidatejobService,
              private documentsService: DocumentsService,
             ) { }

  ngOnInit(): void {
    this.authenticationService.isAuthenticated.subscribe(value => {
      this.authentication = value;
      var temp: string = String(this.authentication);
      window.localStorage.setItem('auth', temp);
    });
    this.currentApplicationStage = new ApplicationStagesModel();
    this.applicationStagesProgrgess = new Array<ApplicationStagesModel>();
    this.applications = new Array<ApplicationModel>();
    this.stages = new Array<StageModel>();
    this.candidateStagesProgress = new Array<CandidateStageProgressModel>();
    this.showMessage = false;
    this.stageNames = Array<string>();
    this.candidateStages = new Array<CandidateStageProgressModel>();
    this.stagesForSvg = Array<CandidateStageProgressModel>();
    this.getCandidate();
  }

  getActive(choice: string): string {
    if (this.currentChoice === choice) {
      return 'active';
    } else {
      return 'not';
    }
  }

  setActive(choice: string): void {
    this.currentChoice = choice;
  }

  logout(): void {
    this.authenticationService.logout();
    this.router.navigate(['login']);
  }

  signIn(): void {
    this.router.navigate(['login']);
  }

  goToProfile(): void {
    this.router.navigate(['profile']);
  }

  goToApplication(b: boolean): void {
    if (b === true){
      this.isLoaded = true;
      if (this.showMessage) {
        this.fetchApplicationGuids(this.candidate.id);
      } else {
        this.modalService.open('myapplications');
      }
    }
  }



  getCandidate(): void {

    const userDetails = this.authenticationService.getUserCredential();
    if (userDetails.username) {
      this.filterCriteria = new FilterCriteria();
      this.filterCriteria.EmailKey = userDetails.username;
    }

    this.candidateService.GetByFilterCriteria(this.filterCriteria).subscribe( result => {

      this.candidate = result[0];
      this.fetchApplicationGuids(this.candidate.id);

      const uniqueApplicationsArray = Array.from(new Set(this.applicationStagesProgrgess));
      this.applicationStagesProgrgess = uniqueApplicationsArray;

      });

  }

  fetchApplicationGuids(candidateid: string): void {
    this.filterCriteria.ApplicationsKeys = new Array<string>();
    this.stagescandidatejobService.GetCandidateApplications(Guid.parse(candidateid)).subscribe( ids => {
      if ( ids.length > 0) {
        const uniQueIDs = new Set<string>();
        /* Filter Duplicates */
        ids.forEach(id => {
          uniQueIDs.add(id);
        });

        uniQueIDs.forEach(id => {
          this.filterCriteria.ApplicationsKeys.push(id);
        });

        console.log(this.filterCriteria);

        this.fetchApplications(this.filterCriteria);

      } else {
        this.showMessage = true;
      }

      /* check if my-applications is clicked */
      if (this.isLoaded === true){
        this.showMessage = true;
        this.modalService.open('myapplications');
      }

    });
  }

  fetchApplications(filterCriteria: FilterCriteria): void {
    this.applicationsService.GetByFilterCriteria(filterCriteria).subscribe(applications => {
      this.applications = applications;
      console.log(this.applications);
      this.applications.forEach( application => {
        if (!application.bannerImage) {
          application.bannerImage = 'assets/images/hazie-banner.jpg';
        }
        this.fetchAllApplicationStages(filterCriteria, application);
      });

      if (this.applications.length === 0) {
        this.showMessage = true;
        this.modalService.close('myapplications');
      }
    });

  }

  fetchApplicationBannerImage(application: ApplicationModel, filterCriteria: FilterCriteria): void {
    this.documentsService.GetById(Guid.parse(application.bannerImage)).subscribe(documentModel => {

      if (documentModel) {
        this.resultDoc = documentModel;
        application.bannerImage = documentModel.fileURL;
        this.currentApplicationStage.bannerImage = application.bannerImage;
        this.fetchAllApplicationStages(filterCriteria, application);
      }

      this.resultDoc = documentModel;
    },
      error =>
      {
        application.bannerImage = '/assets/images/hazie-banner.jpg';
        this.currentApplicationStage.bannerImage = application.bannerImage;
        this.fetchAllApplicationStages(filterCriteria, application);
      });
  }

  fetchAllApplicationStages(filterCriteria: FilterCriteria, application: ApplicationModel): void {
    filterCriteria.ApplicationsKeys = []; /* Clear */
    filterCriteria.ApplicationsKeys.push(application.id);

    this.stageService.GetByFilterCriteria(filterCriteria).subscribe(response => {
      this.stages = response;

      this.stages.forEach(stage => {
        this.stageNames.push(stage.name);
      });

      this.fetchStagesInProgress(this.candidate.id, application, response);
    });
  }

  fetchStagesInProgress(candidateId: string, application: ApplicationModel, applicationStages: StageModel[]): void {
    this.stagescandidatejobService.GetCandidateApplicationStages(candidateId, application.id).subscribe(response => {
      // this.candidateStages = response;
      let stagesInProgress = new Array<CandidateStageProgressModel>();
      stagesInProgress = response;

      this.prepareStagesForSvg(stagesInProgress, application, applicationStages);
    });
  }

  prepareStagesForSvg(stagesInProgress: CandidateStageProgressModel[],
                      application: ApplicationModel,
                      applicationStages: StageModel[]): void {

    const stagesForSvg = new Set<CandidateStageProgressModel>();

    applicationStages.forEach(stage => {
      const stageForSvg = new CandidateStageProgressModel();
      stageForSvg.stageId = stage.id;
      stageForSvg.name = stage.name;
      stageForSvg.progress = StageCandidateApplicationProgress.Incomplete;

      stagesForSvg.add(stageForSvg);
    });

    stagesForSvg.forEach(stage => {
      stagesInProgress.forEach(x => {
        if (x.stageId === stage.stageId) {
          stage.progress = x.progress;
          stage.isPromoted = x.isPromoted;
          x.name = stage.name; /* Repair this.candidateStages */
        }
      });
    });

    const tempCurrentApplication = new ApplicationStagesModel();
    tempCurrentApplication.id = application.id;
    tempCurrentApplication.industry = application.industry;
    tempCurrentApplication.title = application.title;
    tempCurrentApplication.bannerImage = application.bannerImage;
    tempCurrentApplication.stages = Array.from(stagesForSvg);
    this.applicationStagesProgrgess.push(tempCurrentApplication);
    console.log(this.applicationStagesProgrgess);

    this.showMessage = false; /* since my-applications can get called at any time*/
  }





  getIndustry(industry) {
    return Industry[industry];
  }


  toApplication(application: ApplicationStagesModel) {
    this.router.navigate(['candidate-questions', application.id]);
  }

  closeMyApplications() {

    this.modalService.close('myapplications');
  }
}
