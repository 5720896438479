import {Component, Input, OnInit} from '@angular/core';
import {ApplicationModel} from '../../../../Core/Models/Application.Model';
import {Router} from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-application-card',
  templateUrl: './application-card.component.html',
  styleUrls: ['./application-card.component.scss']
})
export class ApplicationCardComponent implements OnInit {

  @Input() application: ApplicationModel;
  @Input() currentId;
  @Input() Auth;
  currentDate: Date;



  constructor(private router: Router) {
  }

  ngOnInit(): void {
    this.currentDate = new Date();

  }


  goToApplicationDetails(applicationId: string): void {
    console.log(applicationId.toString());
    this.router.navigate(['application-details', applicationId]);
  }

  goToApplicationQuestions(id: string): void {
    const authentication = window.localStorage.getItem('auth');
    if (authentication === 'true'){
      this.router.navigate(['candidate-questions', this.currentId]);

    }else{
      this.router.navigate(['login']);
    }

  }

  applicationClosed(x: ApplicationModel): boolean  {
    const date = new Date(x.expiryDate);
    if (moment(date).isBefore(this.currentDate)) {
      return false;
    } else if (moment(date).isSame(this.currentDate)) {
      return false;
    } else if (moment(new Date(date)).isAfter(this.currentDate)) {
      return  true;
    }
  }
}
