import {ApplicationModel} from './Application.Model';
import {CandidateStageProgressModel} from './CandidateStageProgress.Model';
import {LocationApplicationModel} from './LocationApplication.Model';
import {BaseModel} from './Base.Model';

export class ApplicationStagesModel extends BaseModel{
  title: string;
  shortDescription: string;
  longDescription: string;
  salary: number;
  isDeleted: boolean;
  availableDate: Date;
  expiryDate: Date;
  isActive: boolean;
  createdDate: Date;
  deleteDate: Date;
  location = new LocationApplicationModel();
  industry: string;
  companyId: string;
  orderBy: number;
  logoImage: string;
  bannerImage: string;
  stages = new Array<CandidateStageProgressModel>();
}
