export enum LanguageEnum {
  Undefined,
  English,
  Afrikaans,
  IsiNdebele,
  IsiXhosa,
  IsiZulu,
  Sesotho,
  Sepedi,
  Setswana,
  SiSwati,
  Tshivenda,
  Xitsonga,
}
