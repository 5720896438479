import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {ServicesModule} from './Services/services.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {HttpErrorHandlerService} from './Services/ErrorHandlingService/HttpErrorHandler.Service';
import {ComponentsModule} from './Components';

import { LoginComponent } from './Components/accounts/login/login.component';
import { RegisterComponent } from './Components/accounts/register/register.component';
import { ForgetPasswordComponent } from './Components/accounts/forget-password/forget-password.component';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CookieService} from 'ngx-cookie-service';
import {RefreshTokenInterceptor} from './Services/AuthenticationService/refresh-token.interceptor';
import {TokenInterceptor} from './Services/AuthenticationService/token.interceptor';
import {SimpleNotificationsModule} from 'angular2-notifications';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxSpinnerModule} from 'ngx-spinner';
import {LoaderSpinnerService} from './Services/LoaderSpinner/LoaderSpinner.Service';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    ForgetPasswordComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ServicesModule,
    ComponentsModule,
    AngularMultiSelectModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SimpleNotificationsModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: LoaderSpinnerService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorHandlerService, multi: true },
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule { }
