import {StageCandidateApplicationProgress} from '../Enums/Progress.Enums';

export class CandidateStageProgressModel {
  candidateId: string;
  stageId: string;
  applicationId: string;
  name: string;
  orderby: number;
  appliedDate: Date;
  progress: StageCandidateApplicationProgress;
  isPromoted: boolean;
}

