import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {StageModel} from '../../../Core/Models/Stage.Model';
import {Guid} from 'guid-typescript';
import {FilterCriteria} from '../../../Core/Models/FilterCriteria';

@Injectable({
  providedIn: 'root'
})
export class StagesService {

  baseUrl = environment.baseUrl;
  stageEndpoints = `${this.baseUrl}/Stages/Stages/`;

  constructor(private https: HttpClient) {
  }

  GetById(id: Guid): Observable<StageModel> {
    return this.https.get<StageModel>(`${this.stageEndpoints}GetById?id=${id}`);
  }


  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<StageModel[]> {
    return this.https.post<StageModel[]>(`${this.stageEndpoints}all`, filterCriteria);
  }

  Upsert(model: StageModel): Observable<string> {
    return this.https.post<string>(`${this.stageEndpoints}Upsert`, model);
  }

  isActive(id: Guid): Observable<string> {
    return this.https.get<string>(`${this.stageEndpoints}isActive?id=${id}`);
  }
}
