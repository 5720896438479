import {Component, OnInit} from '@angular/core';
import {Guid} from 'guid-typescript';
import {Router} from '@angular/router';
import {ApplicationModel} from '../../../Core/Models/Application.Model';

import {FilterCriteria} from '../../../Core/Models/FilterCriteria';
import {CompaniesService} from '../../../Services/CompaniesService/Companies.Service';
import {CompanyModel} from '../../../Core/Models/Company.Model';
import {ApplicationsService} from '../../../Services/ApplicationsService/Applications.Service';
import {DocumentsService} from '../../../Services/DocumentsService/documents.service';
import {LocationApplicationModel} from '../../../Core/Models/LocationApplication.Model';
import {DocumentUploadModel} from '../../../Core/Models/DocumentUpload.Model';
import {AuthenticationService} from '../../../Services/AuthenticationService/authentication.service';
import {Title} from '@angular/platform-browser';
import {categoryEnum} from '../../../Core/Enums/Category.Enum';

@Component({
  selector: 'app-home',
  templateUrl: './applications-home.component.html',
  styleUrls: ['./applications-home.component.scss']
})
export class ApplicationsHomeComponent implements OnInit {

  title = 'Applications';
  public applicationId: string;
  public applications: ApplicationModel[];
  apps: ApplicationModel[];
  filteredApps: ApplicationModel[];
  tempApplications: ApplicationModel[];
  public app: ApplicationModel;
  companies: CompanyModel[];
  resultDoc: DocumentUploadModel;
  currentChoice = 'all';
  public filterCriteria: FilterCriteria;
  authentication: boolean;
  isLoaded = false;
  choice: string;

   constructor(private router: Router,
               private companyService: CompaniesService,
               private applicationsService: ApplicationsService,
               private documentsService: DocumentsService,
               private authenticationService: AuthenticationService,
               private titleService: Title) {

     this.Authenticate();

     this.filterCriteria = new FilterCriteria();
     this.companies = new Array<CompanyModel>();
     this.applications = new Array<ApplicationModel>();
     this.apps = new  Array<ApplicationModel>();
     this.filteredApps = new  Array<ApplicationModel>();
     this.resultDoc = new DocumentUploadModel();
     this.filterCriteria.ApplicationsKeys = [];
     this.getApplicants();



  }
  ngOnInit(): void {
    this.titleService.setTitle(this.title);
  }

   Authenticate(): void {
     const observableAuth = this.authenticationService.isAuthenticated.subscribe(auth =>{
       this.authentication = auth;
     });
  }
  getApplicants(): void {

     this.applicationsService.GetByFilterCriteria(this.filterCriteria).subscribe(applications => {
       this.applications = applications;
       this.isLoaded = true;

       this.applications.forEach( application => {
         if (application.bannerImage) {
           /* Do nothing */
         } else {
           application.bannerImage = '/assets/images/hazie-banner.jpg';
         }
       });

       this.filteredApps = this.applications.filter( item => !item.isDeleted && item.isActive);
       // this.filteredApps.sort((x, y) => +new Date(y.dateCreated) - +new Date(x.dateCreated));
       this.tempApplications = this.filteredApps;
     });

  }

  filter(substring: string): void {
    if (substring === 'all') {
      this.filteredApps = this.tempApplications;
    } else if (substring === 'Bursaries') {
      this.filteredApps = this.tempApplications.filter(item => item.category === categoryEnum.Bursaries);
    } else if (substring === 'Graduates') {
      this.filteredApps = this.tempApplications.filter(item => item.category === categoryEnum.Graduates);
    } else if (substring === 'Learnerships') {
      this.filteredApps = this.tempApplications.filter(item => item.category === categoryEnum.Learnerships);
    } else if (substring === 'Internships') {
      this.filteredApps = this.tempApplications.filter(item => item.category === categoryEnum.Internships);
    } else if (substring === 'Apprenticeships') {
      this.filteredApps = this.tempApplications.filter(item => item.category === categoryEnum.Apprenticeships);
    }
  }

  getActive(choice: string): string {
    if (this.currentChoice === choice) {
      this.choice = choice;
      this.filter(choice);
      return 'active';
    } else {
      return 'not';
    }
  }

  setActive(choice: string): void {
    this.currentChoice = choice;
  }
}
