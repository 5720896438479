import {BaseModel} from './Base.Model';
import {Gender} from './Gender.Model';

export class LocationModel extends BaseModel {
  lineone: string;
  linetwo: string;
  linethree: string;
  linefour: string;
  candidateId: string;
  code: number;
}

export class CandidateModel extends BaseModel{
  email: string;
  surname: string;
  name: string;
  currentStage: string;
  gender: Gender;
  identityNumber: string;
  passportNumber: number;
  countryOfBirth: string;
  isActive: boolean;
  candidateId: string;
  location: LocationModel;
}
