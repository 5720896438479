import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FilterCriteria} from '../../Core/Models/FilterCriteria';
import {Guid} from 'guid-typescript';
import {QuestionModel} from '../../Core/Models/Question.Model';
import {CandidateAnswerModel} from '../../Core/Models/CandidateAnswer.Model';
import {Injectable} from '@angular/core';

@Injectable()
export class QuestionsAndAnswersService {

  baseUrl = environment.baseUrl;
  QuestionAnswerEndpoints = `${this.baseUrl}/QuestionsAndAnswers/Questions/`;
  constructor(private httpClient: HttpClient) {
  }

  GetById(id: string): Observable<QuestionModel> {
    return this.httpClient.get<QuestionModel>(`${this.QuestionAnswerEndpoints}GetById?id=${id}`);
  }

  GetByFilterCriteria(filterCriteria: FilterCriteria): Observable<QuestionModel[]> {
    return this.httpClient.post<QuestionModel[]>(`${this.QuestionAnswerEndpoints}all`, filterCriteria);
  }

  Upsert(model: QuestionModel): Observable<string> {
    return this.httpClient.post<string>(`${this.QuestionAnswerEndpoints}Upsert`, model);
  }

  GetCandidateAnswer(candidateId: string, questionId: string): Observable<CandidateAnswerModel> {
    return this.httpClient.get<CandidateAnswerModel>(`${this.QuestionAnswerEndpoints}GetCandidateStageAnswer?candidateId=${candidateId}&questionId=${questionId}`);
  }
  UpsertCandidateAnswer(model: CandidateAnswerModel): Observable<string> {
    return this.httpClient.post<string>(`${this.QuestionAnswerEndpoints}UpsertCandidateAnswer`, model);
  }

  GetAllCandidateStageAnswers(candidateId: string, stageId: string): Observable<CandidateAnswerModel[]> {
    return this.httpClient.get<CandidateAnswerModel[]>(`${this.QuestionAnswerEndpoints}GetAllCandidateStageAnswers?candidateid=${candidateId}&stageid=${stageId}`);
  }

  UpdateIsActive(id: string, isActive: boolean): Observable<string> {
    return this.httpClient.get<string>(`${this.QuestionAnswerEndpoints}isActive?id=${id}&isActive=${isActive}`);
  }

  softDelete(id: Guid): Observable<string> {
    return this.httpClient.get<string>(`${this.QuestionAnswerEndpoints}isActive?id=${id}`);
  }
}
