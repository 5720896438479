<div class="container" *ngIf="isLoaded">
  <div class="card" *ngFor="let application of applicationStagesProgrgess" (click)="toApplication(application)">
    <img src="{{application.bannerImage}}" alt="application-banner">
    <div class="info">
      <div class="title">{{application.title}}</div>
      <div class="svg">
        <app-stages-svg [candidateStages]="application.stages"></app-stages-svg>
      </div>
      <div class="card-footer">
        <div class="industry">Industry: {{getIndustry(application.industry)}}</div>
        <div class="company-logo">
          <img src="{{application.logoImage}}" alt="">
        </div>
      </div>
    </div>

  </div>
</div>
